/* eslint-disable react/no-unused-prop-types */
import React, { FunctionComponent } from 'react';
import Style from 'components/controls/Loader/Loader.module.css';
import { useSelector } from 'react-redux';
import { getJobRequestLoading } from 'store/selector/job.selector';
import { Spinner } from 'react-bootstrap';

interface LoaderProps {
  isSmallView?: boolean;
  cssClass?: string;
  className?: string;
}

const Loader: FunctionComponent<LoaderProps> = (props: LoaderProps) => {
  const { isSmallView, cssClass } = props;
  const castingRequestLoading = useSelector(getJobRequestLoading);
  if (isSmallView) {
    return <Spinner as="span" className={cssClass} animation="border" size="sm" role="status" aria-hidden="true" />;
  }
  return (
    <>
      {castingRequestLoading && (
        <div>
          <div className={Style.loaderWrapper} />
          {/* <div className={Style.loaderInner}>
            <div className="lds-hourglass" />
          </div> */}
          <Spinner as="span" className={cssClass} animation="border" size="sm" role="status" aria-hidden="true" />
        </div>
      )}
    </>
  );
};

export default Loader;
