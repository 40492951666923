export const BASE_URL = process.env.REACT_APP_API_URL;
// export const BASE_URL = 'http://localhost:5000/api/';
// export const BASE_URL = 'https://mashghol.com/castingpax/api/';
// export const BASE_URL = 'https://www.castingpax.com/api/';

export const URL_LOGIN = `${BASE_URL}auth/login`;
export const URL_SIGNUP = `${BASE_URL}auth/register`;
export const URL_PAYROLL_INVITE = `${BASE_URL}show/payroll_invitation`;
export const URL_PERFORMER_DETAILS = `${BASE_URL}auth/performer_details`;
export const URL_PERFORMER_VIEW_DETAILS = `${BASE_URL}auth/performer_view`;
export const URL_PUSHER_AUTH = `${BASE_URL}auth/authenticate_channel`;
export const URL_FORGOT_REQUEST = `${BASE_URL}auth/reset_password`;
export const URL_VALIDATE_FORGOT_TOKEN = `${BASE_URL}auth/validate_reset_token`;
export const URL_UPDATE_PASSWORD_TOKEN = `${BASE_URL}auth/new_password`;
export const URL_CONFIRM_EMAIL = `${BASE_URL}auth/cofirm_email`;
export const URL_SUBSCRIPTION_PLANS = `${BASE_URL}admin/v1/subscriptions`;
export const URL_UPDATE_SUBSCRIPTION = `${BASE_URL}auth/change_plan`;
export const URL_UPDATE_STRIPE = `${BASE_URL}auth/connect_account`;
export const URL_ACCOUNT_TOKEN = `${BASE_URL}auth/account_token`;
export const URL_DELETE_ACCOUNT = `${BASE_URL}auth/close_account`;
export const URL_STRIPE_LINKED = `${BASE_URL}auth/stripe_link_account`;
export const URL_BUSINESS_INFO = `${BASE_URL}auth/updateBusinessInfo`;

export const URL_PROFILE_UPDATE = `${BASE_URL}profile/update`;
export const URL_PROFILE_FILE = `${BASE_URL}profile/upload_file`;
export const URL_NOTIFICATIONS = `${BASE_URL}profile/notifications`;
export const URL_NOTIFICATIONS_READ = `${BASE_URL}profile/read_notifications`;
export const URL_PROFILE_SETTING = `${BASE_URL}profile/settings`;
export const URL_GET_PERFORMER_VOUCHER = `${BASE_URL}profile/get_voucher_info`;
export const URL_SAVE_PERFORMER_VOUCHER = `${BASE_URL}profile/create_performer_voucher`;
export const URL_NOTIFICATION_SETTING = `${BASE_URL}auth/update_notification`;
export const URL_PROOF_OF_RESIDENCY_DOCS = `${BASE_URL}profile/performer_files`;
export const URL_REMOVE_RESIDENCY_DOCS = `${BASE_URL}profile/delete_performer_files`;
export const URL_RENAME_RESIDENCY_DOCS = `${BASE_URL}profile/change_files_name`;
export const URL_CREATE_USER_VOUCHER = `${BASE_URL}profile/create_voucher`;

export const URL_SEARCH_DIRECTOR = `${BASE_URL}profile/search_director`;
export const URL_SEARCH_NAME = `${BASE_URL}profile/search_by_name`;
export const URL_SEARCH_PERFORMER = `${BASE_URL}profile/suggested_bg_performer`;
export const URL_SEARCH_COORDINATOR = `${BASE_URL}profile/search_coordinator`;
export const URL_SEARCH_PRODUCER = `${BASE_URL}profile/search_producer`;
export const URL_CREATE_MY_BG_LIST = `${BASE_URL}profile/create_list`;
export const URL_GET_MY_BG_LIST = `${BASE_URL}profile/get_list`;
export const URL_PAYROLL_USER = `${BASE_URL}profile/get_payroll_user`;
export const URL_ADD_PERFOMER_IN_LIST = `${BASE_URL}profile/add_user_in_list`;
export const URL_PERFORMER_BOOKING = `${BASE_URL}profile/performer_bookings`;
export const URL_PERFORMER_CALENDAR = `${BASE_URL}profile/calendar`;
export const URL_ADD_CALENDAR_DATE = `${BASE_URL}profile/add_calendar`;
export const URL_PERFORMER_BOOKING_UPDATES = `${BASE_URL}profile/performer_job_updates`;
export const URL_ADD_SKILLS = `${BASE_URL}profile/user_skills`;
export const URL_DELETE_SKILL = `${BASE_URL}profile/delete_user_skills`;
export const URL_UPLOAD_RENTAL_IMAGE = `${BASE_URL}profile/upload_rental_image`;
export const URL_REMOVE_RENTAL_IMAGE = `${BASE_URL}profile/remove_rental_image`;
export const URL_UPDATE_RENTAL_TYPE = `${BASE_URL}profile/update_rental_type`;
export const URL_UPLOAD_IMAGE = `${BASE_URL}profile/upload_image`;
export const URL_UPLOAD_PERFORMER_FILES = `${BASE_URL}profile/upload_files`;
export const URL_DELETE_IMAGE = `${BASE_URL}profile/delete_image`;
export const URL_CHANGE_IMAGE_ORDER = `${BASE_URL}profile/image_sequence`;
export const URL_CHANGE_FOLDER_ORDER = `${BASE_URL}profile/folder_sequence`;
export const URL_REMOVE_RESUME = `${BASE_URL}profile/remove_resume`;
export const URL_UPDATE_PASSWORD = `${BASE_URL}profile/update_password`;
export const URL_HELP_CENTER = `${BASE_URL}admin/v1/help_center`;
export const URL_PERFORMER_RATE = `${BASE_URL}admin/v1/performer_rate`;
export const URL_CUSTOM_PERFORMER_RATE = `${BASE_URL}admin/v1/performer_custom_rate`;
export const URL_CREATE_THUMBNAIL = `${BASE_URL}profile/create_thumbnail`;
export const URL_SAVE_CARD_INFO = `${BASE_URL}profile/save_card`;
export const URL_FETCH_CARD_DETAILS = `${BASE_URL}profile/card_data`;
export const URL_PERFORMER_IMAGES = `${BASE_URL}profile/performer_images`;
export const URL_DAILY_COSTS = `${BASE_URL}profile/invoices`;
export const URL_PROFILE_SUBSCRIPTION = `${BASE_URL}profile/subscriptions`;
export const URL_CUSTOM_RATES = `${BASE_URL}profile/custom_rates`;
export const URL_SHARE_CUSTOM_RATES = `${BASE_URL}profile/share_custom_rates`;

export const URL_SEARCH_SHOW = `${BASE_URL}show/search_show_by_name`;
export const URL_SHOW_LISTING = `${BASE_URL}show/all`;
export const URL_SHOW_DETAILS = `${BASE_URL}show/show_by_id`;
export const URL_CREATE_SHOW = `${BASE_URL}show/create_show`;
export const URL_UPDATE_SHOW = `${BASE_URL}show/update_show`;
export const URL_CREATE_ROLE = `${BASE_URL}show/create_show_job`;
export const URL_UPDATE_ROLE = `${BASE_URL}show/update_show_job`;
export const URL_CLOSE_DELETE_ROLE = `${BASE_URL}show/close_show_job`;

export const URL_SHOW_JOB_LISTING = `${BASE_URL}show/show_job_listing`;
export const URL_EMAIL_TEMPLATE = `${BASE_URL}show/email_template`;
export const URL_JOB_REQUEST_TO_PERFORMER = `${BASE_URL}show/create_show_job_request`;
export const URL_JOB_REQUEST = `${BASE_URL}show/create_job_request`;
export const URL_SHOW_PERMISSION = `${BASE_URL}show/show_permission`;
export const URL_ACCEPT_SHOW_PERMISSION = `${BASE_URL}show/accept_show_permission`;
export const URL_PERFORMER_PERSONAL_NOTE = `${BASE_URL}show/post_note`;
export const URL_PERFORMER_PERSONAL_NOTE_LIST = `${BASE_URL}show/get_note`;
export const URL_CATTLE_REQUEST = `${BASE_URL}show/filter_show_jobs`;
export const URL_SHOW_WITH_JOBS = `${BASE_URL}show/show_listing`;
export const URL_DELETE_WORKING_DAY = `${BASE_URL}show/remove_working_day`;
export const URL_SHOW_EPISODES = `${BASE_URL}show/show_episodes`;
export const URL_ADD_SHOW_ACCESS = `${BASE_URL}show/add_show_access`;
export const URL_SHOW_EPISODES_DAYS = `${BASE_URL}show/show_episodes_days`;
export const URL_ADD_BUDGET = `${BASE_URL}show/add_budget`;
export const URL_USER_JOB_STATUS = `${BASE_URL}show/user_job_status`;
export const URL_SHOW_FILE_UPLOAD = `${BASE_URL}show/file_upload`;
export const URL_SHOW_IMAGE_UPLOAD = `${BASE_URL}show/show_image`;
export const URL_SHOW_FILE_REMOVE = `${BASE_URL}show/remove_file`;
export const URL_ALL_SHOW = `${BASE_URL}show/all_shows`;
export const URL_BG_COORDINATOR_INVITATION = `${BASE_URL}show/coordinator_invitation`;

export const URL_ACCEPT_REJECT_JOB = `${BASE_URL}job/accept_job`;
export const URL_UPDATE_JOB_READ_STATUS = `${BASE_URL}job/update_read_status`;
export const URL_CANCEL_JOB = `${BASE_URL}job/cancel_job`;
export const URL_CANCEL_BOOKING_BY_DIRECTOR = `${BASE_URL}job/cancel_booking`;
export const URL_ACCEPT_BOOKING_BY_DIRECTOR = `${BASE_URL}job/accept_booking`;
export const URL_SEARCH_JOB = `${BASE_URL}job/job_search`;
export const URL_JOB_SEARCH_FILTER = `${BASE_URL}job/job_search_filter`;
export const URL_JOB_VIEW_VERIFY_URL = `${BASE_URL}job/validate_token`;
export const URL_FETCH_WORKED_DAYS = `${BASE_URL}profile/worked_days`;
export const URL_GET_JOB_ALL_BOOKINGS = `${BASE_URL}job/get_job_bookings`;
export const URL_FETCH_JOB_NOTIFICATION = `${BASE_URL}job/performer_job_booking`;

export const URL_SAVE_EMAIL_TEMPLATE = `${BASE_URL}matrix/email_template`;
// export const URL_MATRIX_DATES = `${BASE_URL}matrix/matrix_dates`;
export const URL_MATRIX_DATES = `${BASE_URL}matrix/matrix_dates_all`;
export const URL_MATRIX_PERFORMER = `${BASE_URL}matrix/performer_data`;
export const URL_NEW_ATOMS = `${BASE_URL}matrix/new_atoms`;
export const URL_ADD_COORDINATOR = `${BASE_URL}matrix/add_coordinator`;
export const URL_BREAKDOWN = `${BASE_URL}matrix/breakdown`;
export const URL_ADD_PRODUCER = `${BASE_URL}matrix/add_producer`;
export const URL_UPDATE_MATRIX_ITEM = `${BASE_URL}matrix/update_matrix_item`;
export const URL_UPDATE_MATRIX_ATOMS_POS = `${BASE_URL}matrix/update_atoms`;
export const URL_UPDATE_ATOMS_TITLE = `${BASE_URL}matrix/update_atoms_title`;
export const URL_DELETE_MATRIX_ITEM = `${BASE_URL}matrix/delete_matrix_item`;
export const URL_DELETE_MATRIX_READ = `${BASE_URL}matrix/delete_matrix_read`;
export const URL_SET_MATRIX_EMAIL_TEMPALTE = `${BASE_URL}matrix/set_email_tempalte`;
export const URL_MATRIX_SEND_EMAIL = `${BASE_URL}matrix/send_email`;
export const URL_UPLOAD_CALL_SHEET_FILE = `${BASE_URL}matrix/call_sheet_file`;
export const URL_REMOVE_CALL_SHEET_FILE = `${BASE_URL}matrix/call_sheet_file_remove`;
export const URL_UPDATE_MATRIX_STATUS = `${BASE_URL}matrix/update_status`;
export const URL_ADD_ITEM_IN_MATRIX = `${BASE_URL}matrix/copy_item`;
export const URL_MATRIX_USERS = `${BASE_URL}matrix/show_users`;
export const URL_MATRIX_USER_SHOW_ACCESS = `${BASE_URL}matrix/user_show_access`;
export const URL_MATRIX_USER_REMOVE_ACCESS = `${BASE_URL}matrix/remove_access`;
export const URL_MATRIX_SHOW_STATS = `${BASE_URL}matrix/show_stats`;
export const URL_PRODUCER_MATRIX = `${BASE_URL}matrix/show_matrix`;
export const URL_BOOKING_STATS = `${BASE_URL}matrix/booking_stats`;
export const URL_CHANGE_USER_ROLE = `${BASE_URL}matrix/change_user_role`;
export const URL_COPY_MATRIX_ITEM = `${BASE_URL}matrix/copy_matrix_item`;
export const URL_UPDATE_MATRIX_PERFORMER_TIME = `${BASE_URL}matrix/update_time`;
export const URL_CREW_CALL = `${BASE_URL}matrix/update_crew_call`;
export const URL_CALCULATE_WAGES = `${BASE_URL}matrix/calculate_wages`;
export const URL_SEND_INVOICE_ON_EMAIL = `${BASE_URL}matrix/send_invoice`;
export const URL_CONFIRM_CALL_SHEET = `${BASE_URL}matrix/confirm_Call_sheet`;
export const URL_VALIDATE_CONFIRM_CALL_SHEET = `${BASE_URL}matrix/validate/confirm_call_sheet`;
export const URL_SEND_BG_SIZE_CARD = `${BASE_URL}matrix/send_bg_size_card`;
export const URL_BREAKDOWN_MATRIX = `${BASE_URL}matrix/breakdown_matrix`;
export const URL_UPDATE_VOUCHER_STATUS = `${BASE_URL}matrix/update_voucher`;
export const URL_CREATE_VOUCHER = `${BASE_URL}matrix/create_voucher`;

export const URL_CREATE_RATE_VALUES = `${BASE_URL}payroll/add_rates`;
export const URL_UPDATE_RATE_VALUES = `${BASE_URL}payroll/update_rates`;
export const URL_VIEW_RATE_VALUES = `${BASE_URL}payroll/get_rate_values`;
export const URL_UPDATE_RATE_TITLE = `${BASE_URL}payroll/custom_rates`;
export const URL_PERFORMER_VOUCHER_STATUS = `${BASE_URL}profile/update_performer_voucher_status`;

export const URL_DOWNLOAD_INVOICE_ON_EMAIL = `${BASE_URL}profile/export_invoices`;
