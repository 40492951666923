import { getPaginationObject, parseShowDatesIntoUTC } from 'helpers/utils';
import { fromJS, List } from 'immutable';
import { EmailTemplate } from 'modules/general/general.type';
import { Pagination, PerformerJobs } from 'modules/jobs/types';
import {
  Episode,
  MatrixAtoms,
  MatrixType,
  PerformerItem,
  Show,
  ShowDayJob,
  ShowDayJobList,
  ShowList,
  RateList,
  ShowTeamMember,
  ShowTypes,
  ShowWorkingDay,
} from 'modules/Show/show.types';
import { BGPerformer, User } from 'modules/user/types';
import ACTIONS, { JOB_STATUS_BOOKED, JOB_STATUS_CANCELLED, SHOW_FROM_NAV_BAR } from 'store/castingPax.constants';

const initialState = fromJS({
  list: {},
  selectedDateLoader: false,
  loading: false,
  error: '',
  selectedShowId: '',
  showToOpen: '',
  selectedShow: {},
  searchUserList: [],
  newShow: {},
  createShowModal: false,
  isShowRequestProcessed: false,
  selectedJob: {},
  showEdit: {},
  dayJobList: {},
  selectedDate: '',
  performerResult: {},
  emailTemplates: [],
  showDetailView: {},
  selectedWorkingDayId: '',
  performerViewMode: true,
  showDetailLoading: false,
  showHistory: {},
  errorNotes: {},
  showCostBudget: {},
  payRollUser: {},
  matrixDate: '',
  showDates: [],
  matrixData: {},
  saveAtoms: false,
  matrixCoordinators: {},
  selectedMatrix: {},
  chartData: {},
  showUserCount: [],
  showPermissions: [],
  updateShowStats: 0,
  redirectedInfo: null,
  jobPostDates: [],
  copiedMatrixResponse: null,
  dailyInvoices: {},
  sendCallSheetLoading: '',
  errorLoading: false,
  recall: false,
  showUploadImageStatus: null,
  performerTab: '-1',
  isCallSheetDeleteLoading: false,
  callSheetCounter: 0,
  isBgSizeCardLoading: false,
  rateList: [],
  customRateList: [],
  breakdownPdf: '',
});

export default function showReducers(state = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.SHOW_SELECTION_CHANGE: {
      const id = action.data;
      const showList = state.getIn(['list', 'data']) || List();
      const index = showList.findIndex((obj: Show) => obj.id === id);
      if (index !== -1) {
        const show = showList[index];
        return state
          .set('selectedShowId', id)
          .set('selectedShow', { ...show })
          .setIn(['dayJobList', 'data'], [])
          .set('selectedJob', {})
          .set('selectedDate', '')
          .set('showDates', [])
          .setIn(['matrixData', 'data'], [])
          .set('selectedWorkingDayId', '');
      }
      return state.set('selectedShowId', id);
    }
    case ACTIONS.SHOW_LISTING_API.PENDING: {
      return state.setIn(['list', 'loading'], true);
    }
    case ACTIONS.SHOW_LISTING_API.SUCCESS: {
      const showList: ShowList = action.data;
      const prevSelectedShow = state.get('selectedShowId');
      const selectedShowId = showList.length > 0 ? showList[showList.length - 1].id : '';
      const updateShowStats = state.get('updateShowStats');
      return state
        .setIn(['list', 'data'], showList)
        .setIn(['list', 'loading'], false)
        .set('updateShowStats', updateShowStats + 1)
        .set('selectedShowId', prevSelectedShow || selectedShowId);
    }
    case ACTIONS.SHOW_LISTING_API.ERROR: {
      return state.setIn(['list', 'loading'], false);
    }
    case ACTIONS.FETCH_SHOW_DETAIL.PENDING: {
      return state.set('showDetailLoading', true);
    }
    case ACTIONS.FETCH_SHOW_DETAIL.SUCCESS: {
      const showList = state.getIn(['list', 'data']) || List();
      const index = showList.findIndex((obj: Show) => obj.id === action.data.id);
      if (index !== -1) {
        showList.splice(index, 1, action.data);

        if (action.showType === SHOW_FROM_NAV_BAR) {
          return state
            .setIn(['list', 'data'], [...showList])
            .set('showDetailLoading', false)
            .set('selectedShow', parseShowDatesIntoUTC(action.data))
            .setIn(['dayJobList', 'data'], [])
            .set('selectedJob', {})
            .set('jobPostDates', [])
            .set('selectedWorkingDayId', '');
        }
        return state.setIn(['list', 'data'], [...showList]).set('showDetailLoading', false);
      }
      return state.setIn(['list', 'data'], [...showList, action.data]).set('showDetailLoading', false);
    }
    case ACTIONS.FETCH_SHOW_DETAIL.ERROR: {
      return state.set('showDetailLoading', false);
    }

    case ACTIONS.SHOW_HISTORY_API.PENDING: {
      return state.set('showHistory', '');
    }
    case ACTIONS.SHOW_HISTORY_API.SUCCESS: {
      return state.set('showHistory', action.data);
    }
    case ACTIONS.SHOW_HISTORY_API.ERROR: {
      return state.set('showHistory', '');
    }

    case ACTIONS.SHOW_COST_BUDGET_API.PENDING: {
      return state.set('showCostBudget', '');
    }
    case ACTIONS.SHOW_COST_BUDGET_API.SUCCESS: {
      return state.set('showCostBudget', action.data);
    }
    case ACTIONS.SHOW_COST_BUDGET_API.ERROR: {
      return state.set('showCostBudget', '');
    }

    case ACTIONS.ERROR_NOTES_API.PENDING: {
      return state.set('errorNotes', '');
    }
    case ACTIONS.ERROR_NOTES_API.SUCCESS: {
      return state.set('errorNotes', action.data);
    }
    case ACTIONS.ERROR_NOTES_API.ERROR: {
      return state.set('errorNotes', '');
    }

    case ACTIONS.NOTES_REVIEW_API.PENDING: {
      return state.set('notesReview', '');
    }
    case ACTIONS.NOTES_REVIEW_API.SUCCESS: {
      return state.set('notesReview', action.data);
    }
    case ACTIONS.NOTES_REVIEW_API.ERROR: {
      return state.set('notesReview', '');
    }

    case ACTIONS.RECENT_ACTIONS_API.PENDING: {
      return state.set('recentActions', '');
    }
    case ACTIONS.RECENT_ACTIONS_API.SUCCESS: {
      return state.set('recentActions', action.data);
    }
    case ACTIONS.RECENT_ACTIONS_API.ERROR: {
      return state.set('recentActions', '');
    }

    case ACTIONS.ADD_SEARCH_USER_IN_LIST: {
      const prevList = state.get('searchUserList');
      const index = prevList.findIndex((x: User) => x.id === action.data.id);
      if (index === -1) {
        const newList = [...prevList, action.data];
        return state.set('searchUserList', newList);
      }
      return state;
    }
    case ACTIONS.REMOVE_SEARCH_USER_IN_LIST: {
      const prevList = state.get('searchUserList');
      const newList = prevList.filter((x: User) => x.id !== action.data.id);
      return state.set('searchUserList', [...newList]);
    }
    case ACTIONS.CREATE_SHOW.PENDING: {
      return state.setIn(['newShow', 'loading'], action.status === 'LOADING');
    }
    case ACTIONS.CREATE_SHOW.SUCCESS: {
      const prevList = state.getIn(['list', 'data']) || [];
      const filterList = prevList.filter((e: Show) => e.id !== action.data.id);
      const newList = [...filterList, action.data];
      const emailTemplates = state.get('emailTemplates') || [];
      if (action.data.templateId) {
        const pos = emailTemplates.findIndex((x: EmailTemplate) => x.id === action.data.templateId);
        if (pos !== -1) {
          emailTemplates[pos].showId = action.data.id;
        }
      }

      return state
        .setIn(['newShow', 'loading'], false)
        .setIn(['newShow', 'error'], '')
        .setIn(['newShow', 'data'], parseShowDatesIntoUTC(action.data))
        .set('showEdit', {})
        .set('showDates', [])
        .set('selectedJob', {})
        .set('jobPostDates', [])
        .set('selectedShow', parseShowDatesIntoUTC(action.data))
        .set('selectedShowId', action.data.id)
        .set('emailTemplates', [...emailTemplates])
        .set('showUploadImageStatus', null)
        .setIn(['list', 'data'], newList);
    }
    case ACTIONS.CREATE_SHOW.ERROR: {
      return state
        .setIn(['newShow', 'loading'], false)
        .setIn(['newShow', 'error'], action.error)
        .setIn(['newShow', 'data'], {});
    }
    case ACTIONS.SHOW_ACCESS_REQUEST.PENDING: {
      return state.setIn(['newShow', 'loading'], true).set('isShowRequestProcessed', false);
    }
    case ACTIONS.SHOW_ACCESS_REQUEST.SUCCESS: {
      return state.setIn(['newShow', 'loading'], false).set('isShowRequestProcessed', true);
    }
    case ACTIONS.SHOW_ACCESS_REQUEST.ERROR: {
      return state.setIn(['newShow', 'loading'], false).set('isShowRequestProcessed', false);
    }
    case ACTIONS.RESET_SHOW_ACCESS_REQUEST: {
      return state.set('isShowRequestProcessed', false);
    }
    case ACTIONS.SHOW_IMAGE_UPLOAD.SUCCESS: {
      return state.set('showUploadImageStatus', { image: action.data, error: '' });
    }
    case ACTIONS.SHOW_IMAGE_UPLOAD.ERROR: {
      const prev = state.get('showUploadImageStatus');
      return state.set('showUploadImageStatus', { image: prev ? prev.image : '', error: action.error });
    }
    case ACTIONS.SET_SHOW_SELECTED_DATE_JOB.PENDING: {
      return state.set('selectedDateLoader', true).set('errorLoading', false).set('recall', false);
    }
    case ACTIONS.SET_SHOW_SELECTED_DATE_JOB.SUCCESS: {
      const showData: Show = state.get('selectedShow');

      if (action.payload.workingDayId) {
        if (showData.showType === ShowTypes.FILM && showData.showWorkingDays) {
          const index = showData.showWorkingDays.findIndex((x: ShowWorkingDay) => x.id === action.payload.workingDayId);
          if (index !== -1) {
            showData.showWorkingDays[index].jobs = action.data;
          }
        } else if (showData.showType === ShowTypes.SERIES && showData.episodes) {
          showData.episodes.map((x: Episode) =>
            x.episodeDays.map((s: ShowWorkingDay) => {
              const d = s;
              if (d.id === action.payload.workingDayId) {
                d.jobs = action.data;
              }
              return d;
            }),
          );
        }
      }
      return state
        .setIn(['dayJobList', 'data'], action.data)
        .set('selectedJob', {})
        .set('jobPostDates', [])
        .set('selectedShow', { ...showData })
        .set('selectedDate', action.payload.date)
        .set('selectedWorkingDayId', action.payload.workingDayId)
        .set('selectedDateLoader', false)
        .set('errorLoading', false)
        .set('recall', false);
    }
    case ACTIONS.SET_SHOW_SELECTED_DATE_JOB.ERROR: {
      return state
        .setIn(['dayJobList', 'data'], [])
        .set('selectedJob', {})
        .set('jobPostDates', [])
        .set('selectedWorkingDayId', '')
        .set('selectedDateLoader', false)
        .set('errorLoading', true);
    }
    case ACTIONS.CLEAR_SHOW_SELECTED_DATE_JOB: {
      return state
        .setIn(['dayJobList', 'data'], [])
        .set('selectedJob', {})
        .set('jobPostDates', [])
        .set('selectedWorkingDayId', '');
    }
    case ACTIONS.RECALL_SELECTED_JOB_DATE: {
      return state.set('recall', true);
    }
    case ACTIONS.CLEAR_JOB_ERROR_MESSAGE: {
      return state.set('error', '');
    }
    case ACTIONS.SET_SHOW_DAY_JOB_LIST: {
      return state.setIn(['dayJobList', 'data'], action.data);
    }
    case ACTIONS.CREATE_EMPTY_SHOW_JOB: {
      return state.set('selectedJob', action.data);
    }
    case ACTIONS.SET_SELETED_JOB: {
      return state
        .set('selectedJob', action.data)
        .set('jobPostDates', [{ userId: '-1', dates: [...action.data.dates] }]);
    }
    case ACTIONS.CREATE_JOB.PENDING: {
      return state.set('loading', action.status === 'LOADING');
    }
    case ACTIONS.CLOSE_DELETE_JOB.SUCCESS:
    case ACTIONS.CREATE_JOB.SUCCESS: {
      const role: ShowDayJob = action.data;
      const prevRoleList: ShowDayJobList = state.getIn(['dayJobList', 'data']);
      let newRoleList: ShowDayJobList = [];
      const index = prevRoleList.findIndex((j: ShowDayJob) => j.id === role.id);
      if (index !== -1) {
        prevRoleList.splice(index, 1, role);
        newRoleList = [...prevRoleList];
      } else {
        newRoleList = [role, ...prevRoleList];
      }

      const prevWdId = state.get('selectedWorkingDayId') || '';
      const showData: Show = state.get('selectedShow');

      if (prevWdId) {
        if (showData.showType === ShowTypes.FILM && showData.showWorkingDays) {
          const pos = showData.showWorkingDays.findIndex((x: ShowWorkingDay) => x.id === prevWdId);
          if (pos !== -1) {
            showData.showWorkingDays[pos].jobs = newRoleList;
          }
        } else if (showData.showType === ShowTypes.SERIES && showData.episodes) {
          showData.episodes.map((x: Episode) => {
            const e: Episode = x;
            const epos = x.episodeDays.findIndex((w: ShowWorkingDay) => w.id === prevWdId);
            if (epos !== -1) {
              e.episodeDays[epos].jobs = newRoleList;
            }
            return e;
          });
        }
      }

      return state
        .set('loading', false)
        .set('error', '')
        .set('selectedShow', { ...showData })
        .set('selectedJob', role)
        .set('jobPostDates', [{ userId: '-1', dates: [...role.dates] }])
        .setIn(['dayJobList', 'data'], newRoleList);
    }
    case ACTIONS.CREATE_JOB.ERROR: {
      return state.set('loading', false).set('error', action.error || 'Request fail');
    }
    case ACTIONS.SET_SELETED_JOB_DATES: {
      return state.set('showDates', action.data);
    }

    case ACTIONS.CALL_SHOW_EDIT: {
      return state.set('showEdit', { ...action.data });
    }
    case ACTIONS.CALL_SHOW_DETAIL: {
      return state.set('showDetailView', action.data);
    }
    case ACTIONS.SET_SHOW_SELECTED_DATE: {
      return state.set('selectedDate', action.data);
    }
    case ACTIONS.SET_PERFORMER_SEARCH.PENDING: {
      return state
        .setIn(['performerResult', 'loading'], true)
        .setIn(['performerResult', 'performerId'], '')
        .setIn(['performerResult', 'error'], null);
    }
    case ACTIONS.SET_PERFORMER_SEARCH.SUCCESS: {
      const response: any = action.data;
      if (response.length > 0) {
        const prevData: User[] = state.getIn(['performerResult', 'data']) || [];
        const pg = response[0].pagination[0];
        const pagination: Pagination = getPaginationObject(pg.page, pg.total, pg.limit);
        const newData: User[] = response[0].data;
        const result: User[] = pagination.page > 1 ? [...prevData, ...newData] : response[0].data;
        return state
          .setIn(['performerResult', 'loading'], false)
          .setIn(['performerResult', 'error'], '')
          .setIn(['performerResult', 'sort'], 1)
          .setIn(['performerResult', 'searchText'], null)
          .setIn(['performerResult', 'pagination'], pagination)
          .setIn(['performerResult', 'data'], result);
      }
      return state
        .setIn(['performerResult', 'loading'], false)
        .setIn(['performerResult', 'error'], '')
        .setIn(['performerResult', 'sort'], 1)
        .setIn(['performerResult', 'searchText'], null)
        .setIn(['performerResult', 'pagination'], [])
        .setIn(['performerResult', 'data'], []);
    }
    case ACTIONS.SET_PERFORMER_SEARCH.ERROR: {
      return state
        .setIn(['performerResult', 'loading'], false)
        .setIn(['performerResult', 'error'], action.error)
        .setIn(['performerResult', 'data'], []);
    }
    case ACTIONS.SORT_PERFORMER_ORDER: {
      const prevVal: number = state.getIn(['performerResult', 'sort']) || 1;
      if (action.data !== prevVal) return state.setIn(['performerResult', 'sort'], action.data);
      return state;
    }
    case ACTIONS.SEARCH_EXISTING_PERFORMER: {
      return state.setIn(['performerResult', 'searchText'], action.data);
    }
    case ACTIONS.SET_JOB_BOOKING_PROCESS.PENDING: {
      return state.setIn(['performerResult', 'performerId'], action.payload.userId);
    }
    case ACTIONS.SET_JOB_BOOKING_PROCESS.SUCCESS: {
      const list: User[] = state.getIn(['performerResult', 'data']) || [];
      if (list.length && action.data.userId) {
        const pos: number = list.findIndex((u: User) => u.id === action.data.userId);
        if (pos !== -1) {
          const updateUser: User = list[pos];
          if (updateUser.job) {
            const jobIndex: number = updateUser.job.findIndex((j: PerformerJobs) => j.id === action.data.id);
            if (jobIndex !== -1) {
              const userJob = updateUser.job[jobIndex];
              if (action.bookingStatus === JOB_STATUS_CANCELLED) {
                updateUser.job = updateUser.job.filter((j: PerformerJobs) => j.id !== action.data.id);
              } else {
                userJob.status = JOB_STATUS_BOOKED;
              }
              console.log('Job Status', updateUser.job);
              list.splice(pos, 1, { ...updateUser });
            }
            return state.setIn(['performerResult', 'data'], [...list]).setIn(['performerResult', 'performerId'], '');
          }
        }
      }
      return state.setIn(['performerResult', 'performerId'], '');
    }
    case ACTIONS.SET_JOB_BOOKING_PROCESS.ERROR: {
      return state.setIn(['performerResult', 'performerId'], '');
    }
    case ACTIONS.LOAD_MORE_BG_PERFORMER: {
      const prev: number = state.getIn(['performerResult', 'loadmore']) || 0;
      return state.setIn(['performerResult', 'loadmore'], prev + 1);
    }
    case ACTIONS.UPDAET_PERFORMER_CALENDAR_LIST: {
      const list: User[] = state.getIn(['performerResult', 'data']) || [];
      if (list.length > 0) {
        const pos: number = list.findIndex((u: User) => u.id === action.userId);
        if (pos !== -1 && list[pos].bgPerformer) {
          const user: User = list[pos];
          if (user.bgPerformer) {
            const performer: BGPerformer = user.bgPerformer;
            performer.calendar = action.data;
            user.bgPerformer = { ...performer };
          }
          const jobs: PerformerJobs[] = user.job || [];
          const index: number = jobs.findIndex((p: PerformerJobs) => p.id === action.job.id);
          if (index !== -1) {
            jobs.splice(index, 1);
          }
          user.job = [...jobs, action.job];
          list.splice(pos, 1, { ...user });
          return state.setIn(['performerResult', 'data'], [...list]);
        }
      }
      return state;
    }
    case ACTIONS.SET_PERFORMER_UPDATED_DETAILS_IN_BG: {
      const list: User[] = state.getIn(['performerResult', 'data']) || [];

      if (list.length > 0 && action.data.length > 0 && action.data[0].data.length > 0) {
        const updatedData: User = action.data[0].data[0];
        const pos: number = list.findIndex((u: User) => u.id === updatedData.id);
        if (pos !== -1) {
          list.splice(pos, 1, { ...updatedData });
          return state.setIn(['performerResult', 'data'], [...list]);
        }
      }
      return state;
    }
    case ACTIONS.UPDATE_PERFORMER_CALEDNAR_ON_JOB_POST: {
      const list: User[] = state.getIn(['performerResult', 'data']) || [];
      if (list.length > 0) {
        const pos: number = list.findIndex((u: User) => u.id === action.data.performerId);
        if (pos !== -1) {
          const user: User = list[pos];
          const jobs: PerformerJobs[] = user.job || [];
          const index: number = jobs.findIndex((p: PerformerJobs) => p.id === action.job.id);
          if (index !== -1) {
            jobs.splice(index, 1);
          }
          user.job = [...jobs, action.job];
          list.splice(pos, 1, { ...user });
          return state.setIn(['performerResult', 'data'], [...list]);
        }
      }
      return state;
    }
    case ACTIONS.SET_CLEAR_SEARCH_RESULT: {
      return state.setIn(['performerResult', 'data'], []);
    }
    case ACTIONS.UPDATE_PRELIM_EMAIL_TEMPLATE_ID: {
      const prevMatrix = state.getIn(['matrixData', 'data']) || null;
      const index = prevMatrix.findIndex((m: MatrixType) => m.id === action.data.showId);
      if (index !== -1) {
        const matrix = prevMatrix[index];
        matrix.prelimEmailTemplateId = action.data.templateId;
        prevMatrix.splice(index, 1, matrix);
        return state.setIn(['matrixData', 'data'], [...prevMatrix]);
      }
      return state;
    }
    case ACTIONS.EMAIL_TEMPLATE.SUCCESS: {
      return state.set('emailTemplates', action.data);
    }
    case ACTIONS.SET_PERFORMER_VIEW_MODE: {
      const prev = state.get('performerViewMode');
      return state.set('performerViewMode', !prev);
    }
    case ACTIONS.MATRIX_SELECT_DATE: {
      return state.set('matrixDate', action.data);
    }
    case ACTIONS.SAVE_MATRIX_EMAIL_TEMPLATE.SUCCESS: {
      const prev = state.get('emailTemplates') || [];
      const index = prev.findIndex((x: EmailTemplate) => x.id === action.data.id);
      if (index !== -1) {
        prev.splice(index, 1, action.data);
        return state.set('emailTemplates', [...prev]);
      }

      return state.set('emailTemplates', [...prev, action.data]);
    }
    case ACTIONS.MATRIX_DATA.PENDING: {
      if (action.data.page === 1) {
        return state
          .setIn(['matrixData', 'data'], [])
          .setIn(['matrixData', 'loading'], true)
          .setIn(['matrixData', 'sort'], 1)
          .set('copiedMatrixResponse', null);
      }
      return state.setIn(['matrixData', 'loading'], true).set('copiedMatrixResponse', null);
    }
    case ACTIONS.MATRIX_DATA.SUCCESS: {
      const prevMatrixData: PerformerItem[] = state.getIn(['matrixData', 'data'], []);
      const newData = action.data.pagination.page === 1 ? action.data.data : prevMatrixData.concat(action.data.data);

      return state
        .setIn(['matrixData', 'data'], [...newData])
        .setIn(['matrixData', 'pagination'], action.data.pagination)
        .setIn(['matrixData', 'sort'], 1)
        .setIn(['matrixData', 'loading'], false);
    }
    case ACTIONS.MATRIX_DATA.ERROR: {
      return state.setIn(['matrixData', 'loading'], false);
    }

    case ACTIONS.MATRIX_UPDATE_TIMING_CALL_BACK: {
      const prev = state.getIn(['matrixData', 'data']);
      const index = prev.findIndex((x: MatrixType) => x.id === action.data[0].workingDayId);
      if (index !== -1) {
        const wd = prev[index];
        action.data.forEach((p: any) => {
          const performerIndex = wd.performers.findIndex((w: PerformerItem) => w.id === p.id);
          if (performerIndex !== -1) {
            const pf: PerformerItem = wd.performers[performerIndex];
            pf.isDataUpdate = true;
            wd.performers.splice(performerIndex, 1, { ...pf });
          }
        });
        prev.splice(index, 1, { ...wd });
        return state.setIn(['matrixData', 'data'], [...prev]);
      }
      return state;
    }

    case ACTIONS.CHANGE_MATRIX_ATOMS_UPDATE_CALL: {
      const prev = state.getIn(['matrixData', 'data']);
      const index = prev.findIndex((x: MatrixType) => x.id === action.workingId);
      if (index !== -1) {
        const wd: MatrixType = prev[index];
        const performerIndex = wd.performers.findIndex((p: PerformerItem) => p.id === action.data.ids[0]);
        if (performerIndex !== -1) {
          const performer = wd.performers[performerIndex];
          performer.isDataUpdate = true;
          wd.performers.splice(performerIndex, 1, { ...performer });
        }
        return state.setIn(['matrixData', 'data'], [...prev]);
      }
      return state;
    }

    case ACTIONS.MATRIX_PERFORMER_DATA.ERROR: {
      return state.setIn(['matrixData', 'performerLoading'], false);
    }
    case ACTIONS.MATRIX_PERFORMER_DATA.SUCCESS: {
      const prev: MatrixType[] = state.getIn(['matrixData', 'data'], []);
      const index = prev.findIndex((x: MatrixType) => x.id === action.payload.workingDayId);
      if (index !== -1 && action.data.length !== 0) {
        const wd = prev[index];
        wd.performers = action.data;
        prev.splice(index, 1, { ...wd });
        return state
          .setIn(['matrixData', 'data'], [...prev])
          .setIn(['matrixData', 'performerLoading'], false)
          .setIn(['matrixData', 'sort'], 1);
      }
      return state.setIn(['matrixData', 'performerLoading'], false);
    }
    case ACTIONS.MATRIX_NEW_ATOMS.PENDING: {
      return state.set('saveAtoms', true);
    }
    case ACTIONS.MATRIX_NEW_ATOMS.SUCCESS: {
      const prev: MatrixType[] = state.getIn(['matrixData', 'data']);
      const index = prev.findIndex((x: MatrixType) => x.id === action.payload.workingDayId);
      if (index !== -1) {
        const wd = prev[index];
        wd.atoms = wd.atoms ? [...wd.atoms, action.data] : [action.data];
        prev.splice(index, 1, wd);
        return state
          .setIn(['matrixData', 'data'], [...prev])
          .set('saveAtoms', false)
          .setIn(['matrixData', 'sort'], 1);
      }

      return state.set('saveAtoms', false);
    }
    case ACTIONS.MATRIX_NEW_ATOMS.ERROR: {
      return state.set('saveAtoms', false);
    }
    case ACTIONS.MATRIX_COORDINATORS: {
      return state.set('matrixCoordinators', { users: action.data, invitation: action.invitation });
    }
    case ACTIONS.MATRIX_ADD_COORDINATOR.SUCCESS: {
      const prev: MatrixType[] = state.getIn(['matrixData', 'data']);
      if (prev && prev.length > 0) {
        const index = prev.findIndex((x: MatrixType) => x.id === action.payload.workingDayId);
        if (index !== -1) {
          const wd = prev[index];
          const pos = wd.bgCoordinator.findIndex((x: User) => x.id === action.payload.id);
          if (action.payload.isAdded) {
            if (pos === -1) {
              wd.bgCoordinator.push(action.data);
            }
          } else {
            wd.bgCoordinator.splice(pos, 1);
          }
          prev.splice(index, 1, wd);
        }
        return state.setIn(['matrixData', 'data'], [...prev]);
      }
      return state;
    }
    case ACTIONS.ADD_PERFORMER_IN_MATRIX: {
      const prevMatrix: MatrixType[] = state.getIn(['matrixData', 'data'], []) || [];

      action.data.workingDayIds.forEach((pt: string) => {
        const index: number = prevMatrix.findIndex((x: MatrixType) => x.id === pt);

        if (index !== -1) {
          const matrixData: MatrixType = prevMatrix[index];
          const matrixPerformers: PerformerItem[] = matrixData.performers || [];
          const allRecordOfPerformer: PerformerItem[] = matrixPerformers.filter(
            (p: PerformerItem) => p.performerId === action.data.performerId,
          );

          if (allRecordOfPerformer) {
            allRecordOfPerformer.forEach((mp: PerformerItem) => {
              const pos: number = matrixPerformers.findIndex((p: PerformerItem) => p.id === mp.id);

              if (pos !== -1 && matrixPerformers[pos].jobId === action.data.jobId) {
                const performerToUpdate: PerformerItem = matrixPerformers[pos];
                const deleteIndex: number = action.data.deleteDayIds.indexOf(pt);

                if (deleteIndex !== -1) {
                  performerToUpdate.status = JOB_STATUS_CANCELLED;
                  performerToUpdate.isDelete = true;
                } else {
                  performerToUpdate.status = action.data.status;
                }

                if (matrixData.atoms) {
                  const atomsPos = matrixData.atoms?.findIndex((a: MatrixAtoms) => a.id === performerToUpdate.atoms);
                  if (atomsPos !== -1) {
                    const num: number = matrixData.atoms[atomsPos].isUpdate;
                    matrixData.atoms[atomsPos].isUpdate = num + 1;
                  }
                }
                matrixPerformers.splice(pos, 1, { ...performerToUpdate });
              }
            });
            matrixData.performers = [...matrixPerformers];
          }
          prevMatrix.splice(index, 1, { ...matrixData });
        }
      });
      return state.setIn(['matrixData', 'data'], [...prevMatrix]);
    }
    case ACTIONS.COPY_PERFORMER_FROM_ABOVE: {
      const prev: MatrixType[] = state.getIn(['matrixData', 'data']) || [];
      const index: number = prev.findIndex((x: MatrixType) => x.id === action.data[0].workingDayId);
      if (index !== -1) {
        const matrixData = prev[index];
        action.data.forEach((p: PerformerItem) => matrixData.performers.push(p));
        prev.splice(index, 1, matrixData);
      }
      return state.setIn(['matrixData', 'data'], [...prev]);
    }
    case ACTIONS.UPLOAD_CALL_SHEET.PENDING: {
      return state.set('isCallSheetLoading', true);
    }
    case ACTIONS.UPLOAD_CALL_SHEET.SUCCESS: {
      const prev: MatrixType[] = state.getIn(['matrixData', 'data']) || [];
      const index = prev.findIndex((x: MatrixType) => x.id === action.payload);
      if (index !== -1) {
        prev[index].callSheetUrl = action.data;
      }
      let counter = state.get('callSheetCounter');
      counter += 1;
      return state
        .setIn(['matrixData', 'data'], [...prev])
        .set('isCallSheetLoading', false)
        .set('callSheetCounter', counter);
    }
    case ACTIONS.UPLOAD_CALL_SHEET.ERROR: {
      let counter = state.get('callSheetCounter');
      counter += 1;
      return state.set('isCallSheetLoading', false).set('callSheetCounter', counter);
    }
    case ACTIONS.SET_SELECTED_MATRIX_ITEM: {
      return state.set('selectedMatrix', action.data);
    }
    case ACTIONS.UDPATE_MATRIX_STATUS.SUCCESS: {
      const prev: MatrixType[] = state.getIn(['matrixData', 'data']) || [];
      const index = prev.findIndex((x: MatrixType) => x.id === action.data.workingDayId);
      if (index !== -1) {
        prev[index].status = action.data.status;
      }
      return state.setIn(['matrixData', 'data'], [...prev]);
    }
    case ACTIONS.GET_MATRIX_USERS.SUCCESS: {
      const shows: Show[] = state.getIn(['list', 'data']) || [];
      const response = action.data;
      const index = shows.findIndex((s: Show) => s.id === action.payload.showId);
      if (index !== -1) {
        const show = shows[index];
        show.team = { data: response.data, pagination: response.pagination };
        shows.splice(index, 1, show);
      }
      return state.setIn(['list', 'data'], [...shows]);
    }
    case ACTIONS.REMOVE_USER_SHOW_ACCESS: {
      const shows: Show[] = state.getIn(['list', 'data']) || [];
      const updateShowStats = state.get('updateShowStats');
      const index = shows.findIndex((s: Show) => s.id === action.data.showId.id);
      if (index !== -1) {
        const show = shows[index];
        if (show.team && show) {
          const { team } = show;
          const pos = team.data.findIndex((t: ShowTeamMember) => t.id === action.data.id);
          if (pos !== -1) {
            team.data.splice(pos, 1);
          }
          show.team = { ...team };
        }
        shows.splice(index, 1, show);
      }
      return state.setIn(['list', 'data'], [...shows]).set('updateShowStats', updateShowStats + 1);
    }
    case ACTIONS.GET_PRODUCER_MATRIX.SUCCESS: {
      const shows: Show[] = state.getIn(['list', 'data']) || [];
      const response = action.data;
      const index = shows.findIndex((s: Show) => s.id === action.payload.id);
      if (index !== -1) {
        const show = shows[index];
        show.matrix = { data: response.data, pagination: response.pagination, stats: response.stats };
        shows.splice(index, 1, show);
      }
      return state.setIn(['list', 'data'], [...shows]);
    }
    case ACTIONS.SHOW_COORDINATOR_INVITATION.SUCCESS: {
      const prev: MatrixType[] = state.getIn(['matrixData', 'data']) || [];
      const coordinator = state.get('matrixCoordinators');

      const index = prev.findIndex((x: MatrixType) => x.id === action.payload.id);
      if (index !== -1) {
        const item = prev[index];
        item.bgInvitation = action.data;
        prev.splice(index, 1, item);
      }
      return state
        .setIn(['matrixData', 'data'], [...prev])
        .set('matrixCoordinators', { users: coordinator.users, invitation: action.data });
    }
    case ACTIONS.GET_BOOKING_STATS.SUCCESS: {
      return state.setIn(['chartData', 'data'], action.data);
    }
    case ACTIONS.CLEAR_BOOKING_STATS: {
      return state.setIn(['chartData', 'data'], []);
    }
    case ACTIONS.GET_SHOW_USER_STATS.SUCCESS: {
      return state.set('showUserCount', action.data.stats).set('showPermissions', action.data.shows);
    }
    case ACTIONS.CHANGE_USER_ROLE.SUCCESS: {
      const shows: Show[] = state.getIn(['list', 'data']) || [];
      const index = shows.findIndex((s: Show) => s.id === action.data.showId);
      if (index !== -1) {
        const show = shows[index];
        const { team } = show;
        const pos = team.data.findIndex((t: ShowTeamMember) => t.id === action.data.id);
        if (pos !== -1) {
          const member = team.data[pos];
          member.showRole = action.data.roleId;
          team.data.splice(pos, 1, { ...member });
          show.team = { ...team };
        }
        shows.splice(index, 1, show);
      }
      return state.setIn(['list', 'data'], [...shows]);
    }
    case ACTIONS.SET_MATRIX_REDIRECTED: {
      return state.set('redirectedInfo', action.data);
    }
    case ACTIONS.CLEAR_MATRIX_REDIRECTED: {
      return state.set('redirectedInfo', null);
    }
    case ACTIONS.SET_SHOW_REDIRECT: {
      return state.set('showToOpen', action.data);
    }
    case ACTIONS.DATE_SELECTION_FOR_JOB_POST: {
      const prevDates = state.get('jobPostDates') || [];
      const selectedJob = state.get('selectedJob') || {};
      if (selectedJob.id) {
        const date: string = `${action.data} 00:00:00`;
        if (selectedJob.dates.indexOf(date) !== -1) {
          const pos: number = prevDates.findIndex((x: any) => x.userId === action.userId);
          if (pos !== -1) {
            const userDates = prevDates[pos];
            const index: number = userDates.dates.indexOf(date);
            if (index !== -1) {
              userDates.dates.splice(index, 1);
            } else {
              userDates.dates.push(date);
            }
            prevDates.splice(pos, 1, { ...userDates });
          } else {
            prevDates.push({ userId: action.userId, dates: selectedJob.dates.filter((s: string) => s !== date) });
          }
          return state.set('jobPostDates', [...prevDates]);
        }
      }
      return state;
    }
    case ACTIONS.MATRIX_COPY_ITEM.SUCCESS: {
      return state.set('copiedMatrixResponse', action.data);
    }
    case ACTIONS.ADD_CUSTOM_RATE.SUCCESS: {
      const rateData = state.get('customRateList') || [];
      rateData.push(action.data.data);
      return state.set('customRateList', [...rateData]);
    }
    case ACTIONS.MATRIX_SEARCH_EXISTING: {
      return state.setIn(['matrixData', 'searchText'], action.data);
    }
    case ACTIONS.USER_MATRIX_SEARCH_EXISTING: {
      return state.setIn(['matrixData', 'searchUserText'], action.data);
    }
    case ACTIONS.MATRIX_DAY_FILTER: {
      return state.setIn(['matrixData', 'dayFilter'], action.data);
    }
    case ACTIONS.MATRIX_RATE_FILTER: {
      return state.setIn(['matrixData', 'rate'], action.data);
    }
    case ACTIONS.MATRIX_ERROR_NOTES_FILTER: {
      if (action.data === 'Error') {
        return state.setIn(['matrixData', 'errors'], action.data);
      }
      return state.setIn(['matrixData', 'notes'], action.data);
    }
    case ACTIONS.MATRIX_HIDE_FILTER: {
      return state.setIn(['matrixData', 'hide'], action.data);
    }
    case ACTIONS.MATRIX_USER_FILTER: {
      return state.setIn(['matrixData', 'recent'], action.data);
    }
    case ACTIONS.MATRIX_DATA_SORT: {
      return state.setIn(['matrixData', 'sort'], action.data);
    }
    case ACTIONS.GET_DAILY_COST.PENDING: {
      return state.setIn(['dailyInvoices', 'loading'], true);
    }
    case ACTIONS.GET_DAILY_COST.SUCCESS: {
      return state
        .setIn(['dailyInvoices', 'data'], action.data.data)
        .setIn(['dailyInvoices', 'pagination'], action.data.pagination)
        .setIn(['dailyInvoices', 'loading'], false)
        .setIn(['dailyInvoices', 'error'], '');
    }
    case ACTIONS.GET_DAILY_COST.ERROR: {
      return state.setIn(['dailyInvoices', 'loading'], false).setIn(['dailyInvoices', 'error'], action.data);
    }
    case ACTIONS.SEND_CALL_SHEET_EMAIL.PENDING: {
      return state.set('sendCallSheetLoading', action.payload.type);
    }
    case ACTIONS.SEND_CALL_SHEET_EMAIL.SUCCESS: {
      const prevMatrix: MatrixType[] = state.getIn(['matrixData', 'data']);
      const workingDayIndex: number = prevMatrix.findIndex((i: MatrixType) => i.id === action.data.workingDayId);
      if (workingDayIndex !== -1) {
        const matrix = prevMatrix[workingDayIndex];
        matrix.status = action.data.type;
        prevMatrix.splice(workingDayIndex, 1, { ...matrix });
      }
      return state.set('sendCallSheetLoading', '').setIn(['matrixData', 'data'], prevMatrix);
    }
    case ACTIONS.SEND_CALL_SHEET_EMAIL.ERROR: {
      return state.set('sendCallSheetLoading', '');
    }
    case ACTIONS.SET_PERFORMER_TAB: {
      return state.set('performerTab', action.data);
    }

    case ACTIONS.CLOSE_DELETE_JOB.PENDING: {
      return state.set('loading', action.status === 'LOADING');
    }
    case ACTIONS.REMOVE_SHOW_JOB: {
      const prevRoleList: ShowDayJobList = state.getIn(['dayJobList', 'data']);
      const index = prevRoleList.findIndex((j: ShowDayJob) => j.id === action.data);
      if (index !== -1) prevRoleList.splice(index, 1);
      return state
        .set('loading', false)
        .set('error', '')
        .set('selectedJob', {})
        .setIn(['dayJobList', 'data'], prevRoleList);
    }
    case ACTIONS.CLOSE_DELETE_JOB.ERROR: {
      return state.set('loading', false).set('error', action.error || 'Request fail');
    }
    case ACTIONS.REMOVE_CALL_SHEET.PENDING: {
      return state.set('isCallSheetDeleteLoading', true);
    }
    case ACTIONS.REMOVE_CALL_SHEET.SUCCESS: {
      const prev: MatrixType[] = state.getIn(['matrixData', 'data']) || [];
      const index = prev.findIndex((x: MatrixType) => x.id === action.data.id);
      if (index !== -1) {
        prev[index].callSheetUrl = JSON.stringify(action.data.callSheetUrl);
      }
      return state.setIn(['matrixData', 'data'], [...prev]).set('isCallSheetDeleteLoading', false);
    }
    case ACTIONS.REMOVE_CALL_SHEET.ERROR: {
      return state.set('isCallSheetDeleteLoading', false);
    }
    case ACTIONS.RESET_COUNTER_CALL_SHEET: {
      return state.set('callSheetCounter', null);
    }

    case ACTIONS.SEND_BG_SIZE_CARD.PENDING: {
      return state.set('isBgSizeCardLoading', true);
    }
    case ACTIONS.SEND_BG_SIZE_CARD.SUCCESS: {
      return state.set('isBgSizeCardLoading', false);
    }
    case ACTIONS.SEND_BG_SIZE_CARD.ERROR: {
      return state.set('isBgSizeCardLoading', false);
    }

    case ACTIONS.GET_PAYROLL_USER.PENDING: {
      return state.set('payRollUser', true);
    }
    case ACTIONS.GET_PAYROLL_USER.SUCCESS: {
      return state.set('payRollUser', action.data);
    }
    case ACTIONS.GET_PAYROLL_USER.ERROR: {
      return state.set('payRollUser', false);
    }

    case ACTIONS.BREAKDOWN.PENDING: {
      return state.set('breakdownPdf', '');
    }
    case ACTIONS.BREAKDOWN.SUCCESS: {
      return state.set('breakdownPdf', action.data);
    }
    case ACTIONS.BREAKDOWN.ERROR: {
      return state.set('breakdownPdf', '');
    }

    case ACTIONS.GET_PERFORMER_RATE.PENDING: {
      return state.set('loading', true);
    }
    case ACTIONS.GET_PERFORMER_RATE.SUCCESS: {
      const rateList: RateList = action.data;
      return state.set('rateList', rateList).set('loading', false);
    }
    case ACTIONS.GET_PERFORMER_RATE.ERROR: {
      return state.set('loading', false);
    }
    case ACTIONS.GET_PERFORMER_CUSTOM_RATE.PENDING: {
      return state.set('loading', true);
    }
    case ACTIONS.GET_PERFORMER_CUSTOM_RATE.SUCCESS: {
      const rateList: RateList = action.data;
      return state.set('customRateList', rateList).set('loading', false);
    }
    case ACTIONS.GET_PERFORMER_CUSTOM_RATE.ERROR: {
      return state.set('loading', false);
    }
    default: {
      return state;
    }
  }
}
