import { List } from 'immutable';
import moment from 'moment-timezone';
import {
  Episode,
  ShowTopCalendarTypeList,
  ShowWorkingDay,
  Show,
  EpisodeDayList,
  MatrixType,
  MatrixItemList,
  ShowTopCalendarType,
} from 'modules/Show/show.types';
import createSelector from 'utils/reselect';
import { EmailTemplate } from 'modules/general/general.type';
import { SelectOptionTypeList } from 'modules/PerformerList/list.types';
import { User, UserList } from 'modules/user/types';
import { defaultPagination } from 'helpers/utils';
import { IFindPerformerFilter } from 'modules/params/param.type';
import { CalendarItem } from 'modules/jobs/types';

const showStateData = (state: any) => {
  const { shows } = state;
  return shows;
};

const authStateData = (state: any) => {
  const { users } = state;
  return users;
};

export const getRecallRequest = createSelector(showStateData, (shows) => shows.get('recall') || false);

export const isLoading = createSelector(showStateData, (shows) => shows.get('loading') || false);

export const getApiError = createSelector(showStateData, (shows) => shows.get('error') || '');

export const showDetailLoading = createSelector(showStateData, (shows) => shows.get('showDetailLoading') || false);

export const getShowLists = createSelector(showStateData, (shows) => shows.getIn(['list', 'data']) || List());
// export const getShowHistory = createSelector(showHistory, (shows) => shows.getIn(['list', 'data']) || List());

export const showLoading = createSelector(showStateData, (shows) => shows.getIn(['list', 'loading']) || false);

export const getShowsForDropDwon = createSelector(showStateData, (shows) => {
  const list = shows.getIn(['list', 'data']) || [];
  return list.map((show: Show) => ({ label: show.title, value: show.id, type: show.showType, id: show.id }));
});

export const getSelectedShowId = createSelector(showStateData, (shows) => shows.get('selectedShowId') || null);

export const getSelectedShow = createSelector(showStateData, (shows) => shows.get('selectedShow') || {});

export const getSelectedshowimage = createSelector(
  showStateData,
  (shows) => shows.getIn(['selectedShow', 'imageFile']) || null,
);

export const getShowForEdit = createSelector(showStateData, (shows) => shows.get('showEdit'));

export const getShowDetail = createSelector(showStateData, (shows) => shows.get('showDetailView'));
export const getShowHistory = createSelector(showStateData, (shows) => shows.get('showHistory'));
export const getShowCostBudget = createSelector(showStateData, (shows) => shows.get('showCostBudget'));
export const getErrorNotes = createSelector(showStateData, (shows) => shows.get('errorNotes'));
export const getNotesReview = createSelector(showStateData, (shows) => shows.get('notesReview'));

export const getRecentActions = createSelector(showStateData, (shows) => shows.get('recentActions'));

export const showCreateLoading = createSelector(showStateData, (shows) => shows.getIn(['newShow', 'loading']));

export const getCallSheetLoading = createSelector(showStateData, (shows) => shows.get('isCallSheetLoading') || null);

export const getCallSheetDeleteLoading = createSelector(
  showStateData,
  (shows) => shows.get('isCallSheetDeleteLoading') || null,
);

export const getCallSheetCounter = createSelector(showStateData, (shows) => shows.get('callSheetCounter') || null);

export const isShowRequestProcessed = createSelector(
  showStateData,
  (shows) => shows.get('isShowRequestProcessed') || false,
);

export const getShowJob = createSelector(showStateData, (shows) => shows.get('selectedJob'));

export const getSelectedShowDate = createSelector(showStateData, (shows) => shows.get('selectedDate'));

export const getSelectedworkingDayId = createSelector(showStateData, (shows) => shows.get('selectedWorkingDayId'));

export const getShowJobList = createSelector(showStateData, (shows) => shows.getIn(['dayJobList', 'data']) || List());
export const showJobListLoading = createSelector(showStateData, (shows) => shows.get('loading') || false);
export const selectedDateLoading = createSelector(showStateData, (shows) => shows.get('selectedDateLoader') || false);
export const showJobListErrorLoading = createSelector(showStateData, (shows) => shows.get('errorLoading') || false);
export const breakdownPdf = createSelector(showStateData, (shows) => shows.get('breakdownPdf') || '');

export const getShowCalendarDates = createSelector(showStateData, (shows) => {
  const arr: ShowTopCalendarTypeList = [];
  const selectedShow = shows.get('selectedShow') || null;
  const cruurentDate = moment().format('YYYY-MM-DD');

  if (selectedShow) {
    selectedShow.episodes?.map((e: Episode) =>
      e.episodeDays.forEach((d: ShowWorkingDay) => {
        if (moment(d.date).isSameOrAfter(cruurentDate, 'day')) {
          arr.push({
            id: d.id,
            date: d.date,
            title: d.title || '',
            dayTitle: moment(d.date).format('MMM DD'),
            workingDayId: d.id,
          });
        }
      }),
    );
    selectedShow.showWorkingDays?.forEach((d: ShowWorkingDay) => {
      if (moment(d.date).isSameOrAfter(cruurentDate, 'day')) {
        arr.push({
          id: d.id,
          date: d.date,
          title: d.title || '',
          dayTitle: moment(d.date).format('MMM DD'),
          workingDayId: d.id,
        });
      }
    });
  }
  return arr.sort((i: ShowTopCalendarType, j: ShowTopCalendarType) => (i.date < j.date ? -1 : 0));
});

export const getShowWorkingDays = createSelector(showStateData, (shows) => {
  const date = moment();
  const arr: EpisodeDayList = [];

  const selectedShow = shows.get('selectedShow') || null;
  if (selectedShow) {
    selectedShow?.episodes?.map((e: Episode) =>
      e.episodeDays.map((d: ShowWorkingDay) => {
        if (moment(d.date).isSameOrAfter(date, 'day')) {
          arr.push(d);
        }
        return d;
      }),
    );
    selectedShow?.showWorkingDays?.map((d: ShowWorkingDay) => {
      if (moment(d.date).isSameOrAfter(date, 'day')) {
        arr.push(d);
      }
      return d;
    });
  }

  return arr;
});

export const getPerformerSearchResult = createSelector(showStateData, (shows) => {
  const results: UserList = shows.getIn(['performerResult', 'data'], []);
  const sortingOrder = shows.getIn(['performerResult', 'sort']) || 1;
  if (sortingOrder === -1 && results.length > 0) {
    return [...results].reverse();
  }
  const filterParams: IFindPerformerFilter = shows.getIn(['performerResult', 'searchText']);

  if (filterParams) {
    let searchResults = results;
    if (filterParams.searchText) {
      const text = filterParams.searchText;

      searchResults = results.filter(
        (u: User) =>
          `${u.firstName.toLowerCase()} ${u.lastName.toLowerCase()}`.includes(text.toLowerCase()) ||
          u.email.toLowerCase().includes(text.toLowerCase()),
      );
    }
    if (filterParams.selectedDate && filterParams.selectedDate.length > 0) {
      const dates = filterParams.selectedDate.join(',');
      searchResults = searchResults.filter((u: User) => {
        const calendarRes = u.bgPerformer?.calendar?.filter(
          (c: CalendarItem) => dates.includes(c.workDate) && c.workStatus === 1,
        );
        return calendarRes && calendarRes.length > 0;
      });
    }
    if (filterParams.activeStatus) {
      searchResults = searchResults.sort((a: User, b: User) => {
        if (a.lastActivity && b.lastActivity) {
          return new Date(b.lastActivity).getTime() - new Date(a.lastActivity).getTime();
        }
        return 1;
      });
    }
    return [...searchResults];
  }
  return results;
});

export const getPerformerSearchSortingOrder = createSelector(
  showStateData,
  (shows) => shows.getIn(['performerResult', 'sort']) || 1,
);

export const performerSearchLoading = createSelector(
  showStateData,
  (shows) => shows.getIn(['performerResult', 'loading']) || false,
);

export const performerSearchPagination = createSelector(
  showStateData,
  (shows) => shows.getIn(['performerResult', 'pagination']) || false,
);

export const getBgLoadMoreCounter = createSelector(
  showStateData,
  (shows) => shows.getIn(['performerResult', 'loadmore']) || 0,
);

export const isRequestingPerformer = createSelector(
  showStateData,
  (shows) => shows.getIn(['performerResult', 'performerId']) || '',
);

export const getEmailTemplates = createSelector(showStateData, (shows) => {
  const selectedShow = shows.get('selectedShow') || null;
  const templates = shows.get('emailTemplates') || [];

  if (selectedShow && 'id' in selectedShow) {
    return templates.filter((et: EmailTemplate) => et.showId === selectedShow.id);
  }
  return templates;
});

export const getEmailTemplateAsOpt = createSelector(showStateData, (shows) => {
  const selectedShow = shows.get('selectedShow') || null;
  const templates = shows.get('emailTemplates') || [];
  const arr: SelectOptionTypeList = [];

  if (selectedShow && 'id' in selectedShow) {
    templates
      .filter(
        (et: EmailTemplate) =>
          et.showId === selectedShow.id && et.createdFrom !== 'matrix' && et.templateType !== 'message',
      )
      .map((t: EmailTemplate) => arr.push({ value: t.id, label: t.title }));
  } else {
    templates.map((t: EmailTemplate) => arr.push({ value: t.id, label: t.title }));
  }
  arr.unshift({ value: '-1', label: 'Add New Template' });
  return arr;
});

export const getRequestTextAsOpt = createSelector(showStateData, (shows) => {
  const selectedShow = shows.get('selectedShow') || null;
  const templates = shows.get('emailTemplates') || [];
  const arr: SelectOptionTypeList = [];

  if (selectedShow && 'id' in selectedShow) {
    templates
      .filter(
        (et: EmailTemplate) =>
          et.showId === selectedShow.id && et.createdFrom !== 'matrix' && et.templateType === 'message',
      )
      .map((t: EmailTemplate) => arr.push({ value: t.id, label: t.title }));
  } else {
    templates
      .filter((et: EmailTemplate) => et.templateType === 'message')
      .map((t: EmailTemplate) => arr.push({ value: t.id, label: t.title }));
  }
  arr.unshift({ value: '-1', label: 'Add New Template' });
  return arr;
});

export const getMatrixEmailTemplateAsOpt = createSelector(showStateData, (shows) => {
  const selectedShow = shows.get('selectedShow') || null;
  const templates = shows.get('emailTemplates') || [];
  const arr: SelectOptionTypeList = [];

  if (selectedShow && 'id' in selectedShow) {
    templates
      .filter((et: EmailTemplate) => et.showId === selectedShow.id && et.createdFrom === 'matrix')
      .map((t: EmailTemplate) => arr.push({ value: t.id, label: t.title }));
  } else {
    templates.map((t: EmailTemplate) => arr.push({ value: t.id, label: t.title }));
  }
  return arr;
});

export const perforemerViewMode = createSelector(showStateData, (shows) => shows.get('performerViewMode'));

export const matrixSelectedDate = createSelector(showStateData, (shows) => shows.get('matrixDate')) || '';

export const getShowSelectedDates = createSelector(showStateData, (shows) => shows.get('showDates') || []);

export const getPerformerRateList = createSelector(showStateData, (shows) => shows.get('rateList') || []);

export const getCustomPerformerRateList = createSelector(showStateData, (shows) => shows.get('customRateList') || []);

export const getMatrixSearchSortingOrder = createSelector(showStateData, (shows) =>
  shows.getIn(['matrixData', 'sort'], 1),
);

export const getMatrixData = createSelector(showStateData, (shows) => {
  const results: MatrixItemList = shows.getIn(['matrixData', 'data']) || [];
  const sortingOrder = shows.getIn(['matrixData', 'sort']) || 1;
  if (sortingOrder === -1 && results.length > 0) {
    return [...results].reverse();
  }
  const rate: string = shows.getIn(['matrixData', 'rate'], '');
  const notes: string = shows.getIn(['matrixData', 'notes'], '');
  const errors: string = shows.getIn(['matrixData', 'errors'], '');
  const hide: string = shows.getIn(['matrixData', 'hide'], '');
  const recent: string = shows.getIn(['matrixData', 'recent'], '');
  if (rate !== '') {
    const filterResult = results.filter((m: MatrixType) => {
      const len: number = 0;
      if (m.performers && m.performers.length > 0) {
        const validSearch = m.performers.filter((u: any) => {
          let obj;
          if (u.rate !== null || u.rate !== '') {
            if (u.rate === rate.toString()) obj = u.rate;
          }
          return obj;
        });
        if (validSearch.length > 0) return m;
      }
      return len > 0;
    });
    return filterResult;
  }
  if (notes !== '') {
    const filterResult = results.filter((m: MatrixType) => {
      const len: number = 0;
      if (m.performers && m.performers.length > 0) {
        const validSearch = m.performers.filter((u: any) => {
          let obj;
          if (u.note !== null && u.note !== '') {
            obj = u;
          }
          return obj;
        });
        if (validSearch.length > 0) return m;
      }
      return len > 0;
    });
    return filterResult;
  }
  if (errors !== '') {
    const filterResult = results.filter((m: MatrixType) => {
      console.log('Performers', m);
      const len: number = 0;
      if (m.performers && m.performers.length > 0) {
        console.log('Performers', m.performers);
        const validSearch = m.performers.filter((u: any) => {
          let obj;
          if (u.voucherStatus !== null && u.voucherStatus !== '' && u.voucherStatus === 'rejected') {
            obj = u;
          }
          return obj;
        });
        if (validSearch.length > 0) return m;
      }
      return len > 0;
    });
    // return filterResult;
  }
  if (hide) {
    const filterResult = results.filter((m: MatrixType) => {
      const len: number = 0;
      if (m.performers && m.performers.length > 0) {
        const validSearch = m.performers.filter((u: any) => {
          let obj;
          if (u.voucherStatus !== null && u.voucherStatus !== '' && u.voucherStatus !== undefined) {
            if (u.voucherStatus !== 'approved') obj = u;
          }
          return obj;
        });
        if (validSearch.length > 0) return m;
      }
      return len > 0;
    });
    return filterResult;
  }
  if (recent !== '' && recent !== 'all') {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    const filterResult = results.filter((m: MatrixType) => {
      const itemDate = new Date(m.date);
      return itemDate >= sevenDaysAgo && itemDate <= new Date();
    });
    return filterResult;
  }
  const text: string = shows.getIn(['matrixData', 'searchText'], '');
  const dayFilter: string = shows.getIn(['matrixData', 'dayFilter'], '');
  if (dayFilter !== '') {
    const filterResult = results.filter((m: MatrixType) => m.date === new Date(dayFilter).toISOString());
    return filterResult;
  }
  if (text !== '') {
    const filterResult = results.filter((m: MatrixType) => {
      const len: number = 0;
      if (m.performers && m.performers.length > 0) {
        const validSearch = m.performers.filter((u: any) => {
          let obj;
          if (u.performerName) {
            obj =
              u.performerName.toLowerCase().includes(text.toLowerCase()) ||
              u.role.toLowerCase().includes(text.toLowerCase());
          } else u.role.toLowerCase().includes(text.toLowerCase());
          return obj;
        });
        if (validSearch.length > 0) return m;
      }
      return len > 0;
    });
    return filterResult;
  }
  return results;
});

export const isMatrixLoading = createSelector(
  showStateData,
  (shows) => shows.getIn(['matrixData', 'loading']) || false,
);

export const matrixPagination = createSelector(showStateData, (shows) =>
  shows.getIn(['matrixData', 'pagination'], defaultPagination()),
);

export const isMatrixPerformerLoading = createSelector(
  showStateData,
  (shows) => shows.getIn(['matrixData', 'performerLoading']) || false,
);

export const isMatrixAtomLoading = createSelector(showStateData, (shows) => shows.get('saveAtoms') || false);

export const getSelectedMatrix = createSelector(showStateData, (shows) => shows.get('selectedMatrix') || {});

export const getMatrixCoordinators = createSelector(showStateData, (shows) => shows.get('matrixCoordinators'));

export const getShowUsers = createSelector(showStateData, authStateData, (shows, users) => {
  const data = shows.get('matrixCoordinators');
  if (data) {
    const coordinators = data.users || [];
    const show: Show = shows.get('selectedShow');
    const loggedInUser: User = users.get('user');
    if (show.users) {
      return {
        users: show.users.filter((x: User) => x.id !== loggedInUser.id).concat(coordinators),
        invitation: data.invitation,
      };
    }
    return coordinators;
  }
  return [];
});

export const getMatrixCoordinatorIds = createSelector(showStateData, (shows) => {
  const coordinators = shows.get('matrixCoordinators');

  if (coordinators) {
    if (coordinators.users.length) return coordinators.users.map((x: User) => x.id);
  }
  return [];
});

export const getChartData = createSelector(showStateData, (shows) => shows.getIn(['chartData', 'data']) || {});

export const getDailyInvoices = createSelector(showStateData, (shows) => shows.getIn(['dailyInvoices', 'data'], []));
export const getDailyInvoicesLoading = createSelector(showStateData, (shows) =>
  shows.getIn(['dailyInvoices', 'loading'], false),
);

export const getDailyInvoicesPagination = createSelector(showStateData, (shows) =>
  shows.getIn(['dailyInvoices', 'pagination'], {}),
);

export const getShowUsersCount = createSelector(showStateData, (shows) => shows.get('showUserCount') || []);

export const getShowPermissions = createSelector(showStateData, (shows) => shows.get('showPermissions') || []);

export const isUpdateShowStats = createSelector(showStateData, (shows) => shows.get('updateShowStats') || 0);

export const redirectedMatrix = createSelector(showStateData, (shows) => shows.get('redirectedInfo') || null);

export const getShowToOpen = createSelector(showStateData, (shows) => shows.get('showToOpen') || '');

export const getJobPostDates = createSelector(showStateData, (shows) => shows.get('jobPostDates'));

export const getpayRollUser = createSelector(showStateData, (shows) => shows.get('payRollUser'));

export const getJobPostWorkingDaysIds = createSelector(showStateData, (shows) => {
  const dates: string[] = shows.get('jobPostDates') || [];
  const selectedJob = shows.get('selectedJob') || null;
  const ids: string[] = [];
  if (selectedJob) {
    dates.forEach((x: string) => {
      const index: number = selectedJob.dates.indexOf(x);
      if (index !== -1) ids.push(selectedJob.workingDayId[index]);
    });
  }
  return ids;
});

export const getCopiedMatrixItemResponse = createSelector(
  showStateData,
  (shows) => shows.get('copiedMatrixResponse') || null,
);

export const getSendCallSheetLoading = createSelector(
  showStateData,
  (shows) => shows.get('sendCallSheetLoading') || '',
);

export const showImageFileName = createSelector(showStateData, (shows) => shows.get('showUploadImageStatus') || '');

export const getPerformerTab = createSelector(showStateData, (shows) => shows.get('performerTab'));

export const getBgSizeCardLoading = createSelector(showStateData, (shows) => shows.get('isBgSizeCardLoading') || false);
