import asyncActionType from 'utils/reduxActions';
import { ScreenSize } from '../modules/general/general.type';

export const FILTER_ACTIONS = {
  SET_SCREEN_WIDTH: '[CASTING_PAX]SET_SCREEN_WIDTH',
  LOGIN: asyncActionType('[CASTING_PAX]LOGIN'),
  LOGIN_CALL: '[CASTING_PAX]LOGIN_CALL',
  AUTHENTICATED_USER: '[CASTING_PAX]AUTHENTICATED_USER',
  AUTH_LOGOUT_BEGIN: '[CASTING_PAX]AUTH_LOGOUT_BEGIN',
  AUTH_LOGOUT: '[CASTING_PAX]AUTH_LOGOUT',
  SIGNUP: asyncActionType('[CASTING_PAX]SIGNUP'),
  SIGNUP_CALL: '[CASTING_PAX]SIGNUP_CALL',
  REGISTER_NEW_USER: '[CASTING_PAX]REGISTER_NEW_USER',
  SIGNUP_STEP_TWO: '[CASTING_PAX]SIGNUP_STEP_TWO',
  PEROFILE_UPDATE: '[CASTING_PAX]PEROFILE_UPDATE',
  CHANGE_SELECTED_TAB: '[CASTING_PAX]CHANGE_SELECTED_TAB',
  RESET_ERROR_MESSAGE: '[CASTING_PAX]RESET_ERROR_MESSAGE',
  DELETE_ACCOUNT_BEGIN: '[CASTING_PAX]DELETE_ACCOUNT_BEGIN',
  DELETE_ACCOUNT: asyncActionType('[CASTING_PAX]DELETE_ACCOUNT'),
  STRIPE_LINKED_ACCOUNT_BEGIN: '[CASTING_PAX]STRIPE_LINKED_ACCOUNT_BEGIN',
  STRIPE_LINKED_ACCOUNT: asyncActionType('[CASTING_PAX]STRIPE_LINKED_ACCOUNT'),
  UPDATE_BUSINESS_INFO_BEGIN: '[CASTING_PAX]UPDATE_BUSINESS_INFO_BEGIN',
  UPDATE_BUSINESS_INFO: asyncActionType('[CASTING_PAX]UPDATE_BUSINESS_INFO'),

  SET_MATRIX_REDIRECTED: '[CASTING_PAX]SET_MATRIX_REDIRECTED',
  CLEAR_MATRIX_REDIRECTED: '[CASTING_PAX]CLEAR_MATRIX_REDIRECTED',
  SET_SHOW_REDIRECT: '[CASTING_PAX]SET_SHOW_REDIRECT',

  PROFILE_FILE_UPLOAD_BEGIN: '[CASTING_PAX]PROFILE_FILE_UPLOAD_BEGIN',
  PROFILE_FILE_UPLOAD: asyncActionType('[CASTING_PAX]PROFILE_FILE_UPLOAD'),

  PROFILE_UPDATE_BEGIN: '[CASTING_PAX]PROFILE_UPDATE_BEGIN',
  PROFILE_UPADTE: asyncActionType('[CASTING_PAX]PROFILE_UPADTE'),

  PERFORMER_FILES_UPLOAD_BEGIN: '[CASTING_PAX]PERFORMER_FILES_UPLOAD_BEGIN',
  PERFORMER_FILES_UPLOAD: asyncActionType('[CASTING_PAX]PERFORMER_FILES_UPLOAD'),

  UPDATE_SUBSCRIPTION_PLAN_BEGIN: '[CASTING_PAX]UPDATE_SUBSCRIPTION_PLAN_BEGIN',
  UPDATE_SUBSCRIPTION_PLAN: asyncActionType('[CASTING_PAX]UPDATE_SUBSCRIPTION_PLAN'),
  SUBSCRIPTION_HISTORY_BEGIN: '[CASTING_PAX]SUBSCRIPTION_HISTORY_BEGIN',
  SUBSCRIPTION_HISTORY: asyncActionType('[CASTING_PAX]SUBSCRIPTION_HISTORY'),

  UPDATE_PERFORMER_DATA: '[CASTING_PAX]UPDATE_PERFORMER_DATA',

  IMAGE_UPLOAD_FILES: '[CASTING_PAX]IMAGE_UPLOAD_FILES',
  IMAGE_DELETE_FILES: '[CASTING_PAX]IMAGE_DELETE_FILES',
  UPDATE_PERFORMER_FILE_COUNTER: '[CASTING_PAX]UPDATE_PERFORMER_FILE_COUNTER',
  DELETE_FILE_ERROR: '[CASTING_PAX]DELETE_FILE_ERROR',

  UPLOAD_RENTAL_IMAGE_BEGIN: '[CASTING_PAX]UPLOAD_RENTAL_IMAGE_BEGIN',
  UPLOAD_RENTAL_IMAGE: asyncActionType('[CASTING_PAX]UPLOAD_RENTAL_IMAGE'),
  UPDATE_PERFORMER_RENTAL_IMAGES: '[CASTING_PAX]UPDATE_PERFORMER_RENTAL_IMAGES',
  REMOVE_RENTAL_IMAGE_BEGIN: '[CASTING_PAX]REMOVE_RENTAL_IMAGE_BEGIN',
  REMOVE_RENTAL_IMAGE: asyncActionType('[CASTING_PAX]REMOVE_RENTAL_IMAGE'),
  REMOVE_PERFORMER_RENTAL_IMAGES: '[CASTING_PAX]REMOVE_PERFORMER_RENTAL_IMAGES',
  UPDATE_RENTAL_TYPE_BEGIN: '[CASTING_PAX]UPDATE_RENTAL_TYPE_BEGIN',
  UPDATE_RENTAL_TYPE: asyncActionType('[CASTING_PAX]UPDATE_RENTAL_TYPE'),
  UPDATE_PERFORMER_RENTAL_TYPE: '[CASTING_PAX]UPDATE_PERFORMER_RENTAL_TYPE',

  ADD_TOAST_IN_LIST: '[CASTING_PAX]ADD_TOAST_IN_LIST',
  REMOVE_TOAST_IN_LIST: '[CASTING_PAX]REMOVE_TOAST_IN_LIST',

  GENERAL_SETTING: asyncActionType('[CASTING_PAX]GENERAL_SETTING'),
  GENERAL_SETTING_BEGIN: '[CASTING_PAX]GENERAL_SETTING_BEGIN',

  NOTIFICATIONS: asyncActionType('[CASTING_PAX]NOTIFICATIONS'),
  NOTIFICATIONS_BEGIN: '[CASTING_PAX]NOTIFICATIONS_BEGIN',

  EMAIL_TEMPLATE: asyncActionType('[CASTING_PAX]EMAIL_TEMPLATE'),
  EMAIL_TEMPLATE_BEGIN: '[CASTING_PAX]EMAIL_TEMPLATE_BEGIN',

  SHOW_SELECTION_CHANGE: '[CASTING_PAX]SHOW_SELECTION_CHANGE',
  SET_SHOW_LISTING: '[CASTING_PAX]SET_SHOW_LISTING',

  SHOW_LISTING_API: asyncActionType('[CASTING_PAX]SHOW_LISTING_API'),
  FETCH_SHOW_LISTING_BEGIN: '[CASTING_PAX]FETCH_SHOW_LISTING_BEGIN',

  GET_PROOF_OF_RESIDENCY: asyncActionType('[CASTING_PAX]GET_PROOF_OF_RESIDENCY'),
  GET_PROOF_OF_RESIDENCY_BEGIN: '[CASTING_PAX]GET_PROOF_OF_RESIDENCY_BEGIN',

  SHOW_HISTORY_API: asyncActionType('[CASTING_PAX]SHOW_HISTORY_API'),
  FETCH_SHOW_HISTORY_BEGIN: '[CASTING_PAX]FETCH_SHOW_HISTORY_BEGIN',

  RECENT_ACTIONS_API: asyncActionType('[CASTING_PAX]RECENT_ACTIONS_API'),
  FETCH_RECENT_ACTIONS_BEGIN: '[CASTING_PAX]FETCH_RECENT_ACTIONS_BEGIN',

  SHOW_COST_BUDGET_API: asyncActionType('[CASTING_PAX]SHOW_COST_API'),
  FETCH_SHOW_COST_BUDGET_BEGIN: '[CASTING_PAX]FETCH_SHOW_COST_BEGIN',

  ERROR_NOTES_API: asyncActionType('[CASTING_PAX]ERROR_NOTES_API'),
  FETCH_ERROR_NOTES_BEGIN: '[CASTING_PAX]FETCH_ERROR_NOTES_BEGIN',

  NOTES_REVIEW_API: asyncActionType('[CASTING_PAX]NOTES_REVIEW_API'),
  FETCH_NOTES_REVIEW_BEGIN: '[CASTING_PAX]FETCH_NOTES_REVIEW_BEGIN',

  FETCH_SHOW_DETAIL: asyncActionType('[CASTING_PAX]FETCH_SHOW_DETAIL'),
  FETCH_SHOW_DETAIL_BEGIN: '[CASTING_PAX]FETCH_SHOW_DETAIL_BEGIN',

  SET_SHOW_SELECTED_DATE: '[CASTING_PAX]SET_SHOW_SELECTED_DATE',
  SET_SHOW_SELECTED_DATE_BEGIN: '[CASTING_PAX]SET_SHOW_SELECTED_DATE_BEGIN',

  RECALL_SELECTED_JOB_DATE: '[CASTING_PAX]RECALL_SELECTED_JOB_DATE',

  SET_SHOW_SELECTED_DATE_JOB: asyncActionType('[CASTING_PAX]SET_SHOW_SELECTED_DATE_JOB'),
  CLEAR_SHOW_SELECTED_DATE_JOB: '[CASTING_PAX]CLEAR_SHOW_SELECTED_DATE_JOB',

  FETCH_SHOW_JOB_LISTING: asyncActionType('[CASTING_PAX]FETCH_SHOW_JOB_LISTING'),
  FETCH_SHOW_JOB_LISTING_BEGIN: '[CASTING_PAX]FETCH_SHOW_JOB_LISTING_BEGIN',

  SET_CLEAR_SEARCH_RESULT: '[CASTING_PAX]SET_CLEAR_SEARCH_RESULT',
  SET_PERFORMER_SEARCH_BEGIN: '[CATING_PAX]SET_PERFORMER_SEARCH_BEGIN',
  SET_PERFORMER_SEARCH: asyncActionType('[CATING_PAX]SET_PERFORMER_SEARCH'),
  LOAD_MORE_BG_PERFORMER: '[CASTING_PAX]LOAD_MORE_BG_PERFORMER',
  SORT_PERFORMER_ORDER: '[CASTING_PAX]SORT_PERFORMER_ORDER',
  SORT_JOB_RESULT_ORDER: '[CASTING_PAX]SORT_JOB_RESULT_ORDER',
  SEARCH_EXISTING_PERFORMER: '[CASTING_PAX]SEARCH_EXISTING_PERFORMER',

  SET_CREATE_MY_PERFORMER_LIST: asyncActionType('[CASTING_PAX]SET_CREATE_MY_PERFORMER_LIST'),
  SET_CREATE_MY_PERFORMER_LIST_BEGIN: '[CASTING_PAX]SET_CREATE_MY_PERFORMER_LIST_BEGIN',

  FETCH_PERFORMER_DETAIL: asyncActionType('[CASTING_PAX]FETCH_PERFORMER_DETAIL'),
  FETCH_PERFORMER_DETAIL_BEGIN: '[CASTING_PAX]FETCH_PERFORMER_DETAIL_BEGIN',

  FETCH_PERFORMER_BOOKINGS: asyncActionType('[CASTING_PAX]FETCH_PERFORMER_BOOKINGS'),
  FETCH_PERFORMER_BOOKINGS_BEGIN: '[CASTING_PAX]FETCH_PERFORMER_BOOKINGS_BEGIN',

  FETCH_PERFORMER_WORKED_DAYS: asyncActionType('[CASTING_PAX]FETCH_PERFORMER_WORKED_DAYS'),
  FETCH_PERFORMER_WORKED_DAYS_BEGIN: '[CASTING_PAX]FETCH_PERFORMER_WORKED_DAYS_BEGIN',

  FETCH_PERFORMER_PERSONAL_NOTE: asyncActionType('[CASTING_PAX]FETCH_PERFORMER_PERSONAL_NOTE'),
  FETCH_PERFORMER_PERSONAL_NOTE_BEGIN: '[CASTING_PAX]FETCH_PERFORMER_PERSONAL_NOTE_BEGIN',
  SET_PERFORMER_PERSONAL_NOTE: '[CASTING_PAX]SET_PERFORMER_PERSONAL_NOTE',

  SET_ADD_PERFOMER_IN_MY_LIST_BEGIN: '[CATING_PAX]SET_ADD_PERFOMER_IN_MY_LIST_BEGIN',
  SET_ADD_PERFOMER_IN_MY_LIST: asyncActionType('[CATING_PAX]SET_ADD_PERFOMER_IN_MY_LIST'),

  FETCH_PERFORMER_UPDATED_DETAILS_BEGIN: '[CATING_PAX]FETCH_PERFORMER_UPDATED_DETAILS_BEGIN',
  SET_PERFORMER_UPDATED_DETAILS_IN_BG: '[CATING_PAX]SET_PERFORMER_UPDATED_DETAILS_IN_BG',
  SET_PERFORMER_UPDATED_DETAILS_IN_LIST: '[CATING_PAX]SET_PERFORMER_UPDATED_DETAILS_IN_LIST',

  ADD_SEARCH_USER_IN_LIST: '[CASTING_PAX]ADD_SEARCH_USER_IN_LIST',
  REMOVE_SEARCH_USER_IN_LIST: '[CASTING_PAX]REMOVE_SEARCH_USER_IN_LIST',

  CREATE_SHOW: asyncActionType('[CASTING_PAX]CREATE_SHOW'),
  CREATE_SHOW_BEGIN: '[CASTING_PAX]CREATE_SHOW',
  UPDATE_SHOW_BEGIN: '[CASTING_PAX]UPDATE_SHOW_BEGIN',
  CALL_SHOW_EDIT: '[CASTING_PAX]CALL_SHOW_EDIT',
  CALL_SHOW_DETAIL: '[CASTING_PAX]CALL_SHOW_DETAIL',

  SHOW_IMAGE_UPLOAD_BEING: '[CASTING_PAX]SHOW_IMAGE_UPLOAD_BEING',
  SHOW_IMAGE_UPLOAD: asyncActionType('[CASTING_PAX]SHOW_IMAGE_UPLOAD'),

  SHOW_ACCESS_REQUEST_BEGIN: '[CASTING_PAX]SHOW_ACCESS_REQUEST_BEGIN',
  SHOW_ACCESS_REQUEST: asyncActionType('[CASTING_PAX]SHOW_ACCESS_REQUEST'),
  RESET_SHOW_ACCESS_REQUEST: '[CASTING_PAX]RESET_SHOW_ACCESS_REQUEST',

  CREATE_EMPTY_SHOW_JOB: '[CASTING_PAX]CREATE_EMPTY_SHOW_JOB',
  SET_SELETED_JOB: '[CASTING_PAX]SET_SELETED_JOB',
  SET_SHOW_DAY_JOB_LIST: '[CASTING_PAX]SET_SHOW_DAY_JOB_LIST',
  CREATE_JOB_BEGIN: '[CASTING_PAX]CREATE_JOB_BEGIN',
  CREATE_JOB: asyncActionType('[CASTING_PAX]CREATE_JOB'),

  CLOSE_DELETE_JOB_BEGIN: '[CASTING_PAX]CLOSE_DELETE_JOB_BEGIN',
  CLOSE_DELETE_JOB: asyncActionType('[CASTING_PAX]CLOSE_DELETE_JOB'),
  REMOVE_SHOW_JOB: '[CASTING_PAX]REMOVE_SHOW_JOB',

  ADD_JOB: asyncActionType('[CASTING_PAX]ADD_JOB'),
  SET_SELETED_JOB_DATES: '[CASTING_PAX]SET_SELETED_JOB_DATES',

  CLEAR_JOB_ERROR_MESSAGE: '[CASTING_PAX]CLEAR_JOB_ERROR_MESSAGE',

  FETCH_MY_PERFORMER_LIST: asyncActionType('[CASTING_PAX]FETCH_MY_PERFORMER_LIST'),
  FETCH_MY_PERFORMER_LIST_BEGIN: '[CASTING_PAX]FETCH_MY_PERFORMER_LIST_BEGIN',
  SET_SELECTED_PERFORMER_FOR_VIEW: '[CASTING_PAX]SET_SELECTED_PERFORMER_FOR_VIEW',

  FETCH_MY_PERFORMER_IMAGES: asyncActionType('[CASTING_PAX]FETCH_MY_PERFORMER_IMAGES'),
  FETCH_MY_PERFORMER_IMAGES_BEGIN: '[CASTING_PAX]FETCH_MY_PERFORMER_IMAGES_BEGIN',

  FETCH_USER_JOB_STATUS: asyncActionType('[CASTING_PAX]FETCH_USER_JOB_STATUS'),
  FETCH_USER_JOB_STATUS_BEGIN: '[CASTING_PAX]FETCH_USER_JOB_STATUS_BEGIN',

  FETCH_CASTING_REQUEST_BEGIN: '[CASTING_PAX]FETCH_CASTING_REQUEST_BEGIN',
  FETCH_CASTING_REQUEST: asyncActionType('[CASTING_PAX]FETCH_CASTING_REQUEST'),

  FETCH_BOOKING_REQUEST_BEGIN: '[CASTING_PAX]FETCH_BOOKING_REQUEST_BEGIN',
  FETCH_BOOKING_REQUEST: asyncActionType('[CASTING_PAX]FETCH_BOOKING_REQUEST'),

  ACCEPT_JOB_BOOKING_PROCESS_BEGIN: '[CASTING_PAX]ACCEPT_JOB_BOOKING_PROCESS_BEGIN',
  CANCEL_JOB_BOOKING_PROCESS_BEGIN: '[CASTING_PAX]CANCEL_JOB_BOOKING_PROCESS_BEGIN',
  SET_JOB_BOOKING_PROCESS: asyncActionType('[CASTING_PAX]SET_JOB_BOOKING_PROCESS'),
  UPDAET_PERFORMER_CALENDAR_LIST: asyncActionType('[CASTING_PAX]UPDAET_PERFORMER_CALENDAR_LIST'),

  FETCH_CALENDAR_REQUEST_BEING: '[CASTING_PAX]FETCH_CALENDAR_REQUEST_BEGIN',
  FETCH_CALENDAR_REQUEST: asyncActionType('[CASTING_PAX]FETCH_CALENDAR_REQUEST'),

  FETCH_CASTING_CALL_REQUEST_BEGIN: '[CASTING_PAX]FETCH_CASTING_CALL_REQUEST_BEGIN',
  FETCH_CASTING_CALL_REQUEST: asyncActionType('[CASTING_PAX]FETCH_CALL_CASTING_REQUEST'),

  FETCH_CASTING_UPDATE_NEWS_BEGIN: '[CASTING_PAX]FETCH_CASTING_UPDATE_NEWS_BEGIN',
  FETCH_CASTING_UPDATE_NEWS: asyncActionType('[CASTING_PAX]FETCH_CASTING_UPDATE_NEWS'),

  SET_CANCEL_BOOKING_BEGIN: '[CASTING_PAX]SET_CANCEL_BOOKING_BEGIN',
  SET_CANCEL_BOOKING: asyncActionType('[CASTING_PAX]SET_CANCEL_BOOKING'),
  SET_CANCEL_BOOKING_LOCALLY: '[CASTING_PAX]SET_CANCEL_BOOKING_LOCALLY',

  SET_ACCEPT_CASTING_REQUEST_BEGIN: '[CASTING_PAX]SET_ACCEPT_CASTING_REQUEST_BEGIN',
  SET_ACCEPT_CASTING_REQUEST: asyncActionType('[CASTING_PAX]SET_ACCEPT_CASTING_REQUEST'),
  SET_ACCEPT_CASTING_REQUEST_LOCALLY: '[CASTING_PAX]SET_ACCEPT_CASTING_REQUEST_LOCALLY',

  ADD_NEW_BOOKING: '[CASTING_PAX]ADD_NEW_BOOKING',
  ADD_NEW_CASTING_REQUEST: '[CASTING_PAX]ADD_NEW_CASTING_REQUEST',
  ADD_NEW_JOB_UPDATE_MESSAGE: '[CASTING_PAX]ADD_NEW_JOB_UPDATE_MESSAGE',
  ADD_NEW_CASTING_CALL: '[CASTING_PAX]ADD_NEW_CASTING_CALL',
  DELETE_JOB_MESSAGE: '[CASTING_PAX]DELETE_JOB_MESSAGE',
  CLOSE_JOB_MESSAGE: '[CASTING_PAX]CLOSE_JOB_MESSAGE',

  ADD_CUSTOM_RATE_BEGIN: '[CASTING_PAX]ADD_CUSTOM_RATE_BEGIN',
  ADD_CUSTOM_RATE: asyncActionType('[CASTING_PAX]ADD_CUSTOM_RATE'),

  SHARE_CUSTOM_RATE_BEGIN: '[CASTING_PAX]SHARE_CUSTOM_RATE_BEGIN',
  SHARE_CUSTOM_RATE: asyncActionType('[CASTING_PAX]ADD_CUSTOM_RATE'),

  SET_JOB_FOR_VIEW: '[CASTING_PAX]SET_JOB_FOR_VIEW',
  CLOSE_JOB_FOR_VIEW: '[CASTING_PAX]CLOSE_JOB_FOR_VIEW',

  FETCH_PERFORMER_PROFILE_BEGIN: '[CASTING_PAX]FETCH_PERFORMER_PROFILE_BEGIN',
  FETCH_PERFORMER_PROFILE: asyncActionType('[CASTING_PAX]FETCH_PERFORMER_PROFILE'),

  SET_JOB_UPDATE_STATUS_READ_BEGIN: '[CASTING_PAX]SET_JOB_UPDATE_STATUS_READ_BEGIN',
  SET_JOB_UPDATE_STATUS_READ: asyncActionType('[CASTING_PAX]SET_JOB_UPDATE_STATUS_READ'),

  SUBMIT_JOB_REQUEST_BEGIN: '[CASTING_PAX]SUBMIT_JOB_REQUEST_BEGIN',
  SUBMIT_JOB_REQUEST: asyncActionType('[CASTING_PAX]SUBMIT_JOB_REQUEST'),
  SUBMIT_JOB_REQUEST_LOCALLY: '[CASTING_PAX]SUBMIT_JOB_REQUEST_LOCALLY',

  CLEAR_SEARCH_JOB_RESULT: '[CASTING_PAX]CLEAR_SEARCH_JOB_RESULT',
  SET_SEARCH_JOBS_BEGIN: '[CASTING_PAX]SET_SEARCH_JOBS_BEGIN',
  SET_SEARCH_JOBS: asyncActionType('[CASTING_PAX]SET_SEARCH_JOBS'),
  SET_SEARCH_JOB_UPDATE_PAGE: asyncActionType('[CASTING_PAX]SET_SEARCH_JOB_UPDATE_PAGE'),

  SET_PERFORMER_VIEW_MODE: '[CASTING_PAX]SET_PERFORMER_VIEW_MODE',

  CALL_JOB_REQUEST_BEGIN: '[CASTING_PAX]CALL_JOB_REQUEST_BEGIN',
  CALL_JOB_REQUEST: asyncActionType('[CASTING_PAX]CALL_JOB_REQUEST'),

  UPDATE_CALENDAR_STATUS_LOCAL: '[CASTING_PAX]UPDATE_CALENDAR_STATUS_LOCAL',
  UPDATE_CALENDAR_STATUS_BEGIN: '[CASTING_PAX]UPDATE_CALENDAR_STATUS_BEGIN',
  UPDATE_CALENDAR_STATUS: asyncActionType('[CASTING_PAX]UPDATE_CALENDAR_STATUS'),

  ADD_NEW_NOTIFICATION: '[CASTING_PAX]ADD_NEW_NOTIFICATION',
  RESET_NEW_NOTIFICATION_ICON: '[CASTING_PAX]RESET_NEW_NOTIFICATION_ICON',
  SHOW_MESSAGE_BOX: '[CASTING_PAX]SHOW_MESSAGE_BOX',
  CLEAR_SHOW_MESSAGE_BOX: '[CASTING_PAX]CLEAR_SHOW_MESSAGE_BOX',
  SET_ALL_SKILLS_FALSE: '[CASTING_PAX]SET_ALL_SKILLS_FALSE',

  SAVE_PERFORMER_SKILL_BEING: '[CASTING_PAX]SAVE_PERFORMER_SKILL_BEGIN',
  SAVE_PERFORMER_SKILL: asyncActionType('[CASTING_PAX]SAVE_PERFORMER_SKILL'),

  DELETE_PERFORMER_SKILL_BEGIN: '[CASTING_PAX]DELETE_PERFORMER_SKILL_BEGIN',
  DELETE_PERFORMER_SKILL: asyncActionType('[CASTING_PAX]DELETE_PERFORMER_SKILL'),

  SAVE_PERFORMER_SKILL_SIGN_UP_BEGIN: '[CASTING_PAX]SAVE_PERFORMER_SKILL_SIGN_UP_BEGIN',
  SAVE_PERFORMER_SKILL_SIGN_UP: asyncActionType('[CASTING_PAX]SAVE_PERFORMER_SKILL_SIGN_UP'),

  SAVE_PERFORMER_IMAGE_BEGIN: '[CASTING_PAX]SAVE_PERFORMER_IMAGE_BEGIN',
  SAVE_PERFORMER_IMAGE: asyncActionType('[CASTING_PAX]SAVE_PERFORMER_IMAGE'),
  ADD_IMAGE_UPLOAD_COUNTER: '[CASTING_PAX]ADD_IMAGE_UPLOAD_COUNTER',
  UPDATE_PERFORMER_IMAGE_RESUME_FILE: '[CASTING_PAX]UPDATE_PERFORMER_IMAGE_RESUME_FILE',

  CREATE_USER_VOUCHER_BEGIN: '[CASTING_PAX]CREATE_USER_VOUCHER_BEGIN',
  CREATE_USER_VOUCHER: asyncActionType('[CASTING_PAX]CREATE_USER_VOUCHER'),

  DELETE_PERFORMER_IMAGE_LOCALLY: '[CASTING_PAX]DELETE_PERFORMER_IMAGE_LOCALLY',
  DELETE_PERFORMER_IMAGE_AUTH: '[CASTING_PAX]DELETE_PERFORMER_IMAGE_AUTH',
  DELETE_PERFORMER_IMAGE_BEGIN: '[CASTING_PAX]DELETE_PERFORMER_IMAGE_BEGIN',
  DELETE_PERFORMER_IMAGE: asyncActionType('[CASTING_PAX]DELETE_PERFORMER_IMAGE'),

  CHANGE_IMAGE_ORDER_BEGIN: '[CASTING_PAX]CHANGE_IMAGE_ORDER_BEGIN',
  CHANGE_IMAGE_ORDER: asyncActionType('[CASTING_PAX]CHANGE_IMAGE_ORDER'),

  OPEN_FULL_VIEW_IMAGE: '[CASTING_PAX]OPEN_FULL_VIEW_IMAGE',

  DELETE_RESUME_BEGIN: '[CASTING_PAX]DELETE_RESUME_BEGIN',
  DELETE_RESUME: asyncActionType('[CASTING_PAX]DELETE_RESUME'),
  REMOVE_RESIDENCY_DOCS_BEGIN: '[CASTING_PAX]REMOVE_RESIDENCY_DOCS_BEGIN',
  DELETE_RESIDENCY_DOCS: asyncActionType('[CASTING_PAX]DELETE_RESIDENCY_DOCS'),
  RENAME_RESIDENCY_DOCS_BEGIN: '[CASTING_PAX]RENAME_RESIDENCY_DOCS_BEGIN',
  RENAME_RESIDENCY_DOCS: asyncActionType('[CASTING_PAX]RENAME_RESIDENCY_DOCS'),

  UPDATE_JOB_SEARCH_FILTER_LIST: '[CASTING_PAX]UPDATE_JOB_SEARCH_FILTER_LIST',
  FETCH_JOB_SEARCH_FILTER_BEGIN: '[CASTING_PAX]FETCH_JOB_SEARCH_FILTER_BEGIN',
  FETCH_JOB_SEARCH_FILTER: asyncActionType('[CASTING_PAX]FETCH_JOB_SEARCH_FILTER'),

  SET_NOTIFICATION_FOR_MARK_READ_BEGIN: '[CASTING_PAX]SET_NOTIFICATION_FOR_MARK_READ_BEGIN',
  SET_NOTIFICATION_FOR_MARK_READ: asyncActionType('[CASTING_PAX]SET_NOTIFICATION_FOR_MARK_READ'),
  SET_NOTIFICATION_FOR_MARK_READ_LOCALLY: '[CASTING_PAX]SET_NOTIFICATION_FOR_MARK_READ_LOCALLY',

  JOB_FOR_VIEW_FROM_NOTIFICATIONS: '[CASTING_PAXJOB_FOR_VIEW_FROM_NOTIFICATIONS',
  PERFORMER_JOB_BOOKING_NOTIFICATION_VIEW: '[CASTING_PAX]PERFORMER_JOB_BOOKING_NOTIFICATION_VIEW',
  SET_PERFORMER_JOB_BOOKING_NOTIFICATION_VIEW: '[CASTING_PAX]SET_PERFORMER_JOB_BOOKING_NOTIFICATION_VIEW',

  SAVE_MATRIX_EMAIL_TEMPLATE_BEGIN: '[CASTING_PAX]SAVE_MATRIX_EMAIL_TEMPLATE_BEGIN',
  SAVE_MATRIX_EMAIL_TEMPLATE: asyncActionType('[CASTING_PAX]SAVE_MATRIX_EMAIL_TEMPLATE'),

  UPDATE_PRELIM_EMAIL_TEMPLATE_ID: '[CASTING_PAX]UPDATE_PRELIM_EMAIL_TEMPLATE_ID',

  MATRIX_SELECT_DATE: '[CASTING_PAX]MATRIX_SELECT_DATE',
  UPDATE_BOOKING_STATS: '[CASTING_PAX]UPDATE_BOOKING_STATS',
  REMOVE_JOB_FROM_BOOKING: '[CASTING_PAX]REMOVE_JOB_FROM_BOOKING',

  MATRIX_DATA_BEGIN: '[CASTING_PAX]MATRIX_DATA_BEGIN',
  MATRIX_DATA: asyncActionType('[CASTING_PAX]MATRIX_DATA'),
  MATRIX_PERFORMER_DATA_BEGIN: '[CASTING_PAX]MATRIX_PERFORMER_DATA_BEGIN',
  MATRIX_PERFORMER_DATA: asyncActionType('[CASTING_PAX]MATRIX_PERFORMER_DATA'),
  ADD_PERFORMER_IN_MATRIX: '[CASTING_PAX]ADD_PERFORMER_IN_MATRIX',

  BREAKDOWN_BEGIN: '[CASTING_PAX]BREAKDOWN_BEGIN',
  BREAKDOWN: asyncActionType('[CASTING_PAX]BREAKDOWN'),

  MATRIX_RATE_FILTER: '[CASTING_PAX]MATRIX_RATE_FILTER',
  MATRIX_HIDE_FILTER: '[CASTING_PAX]MATRIX_HIDE_FILTER',
  MATRIX_ERROR_NOTES_FILTER: '[CASTING_PAX]MATRIX_ERROR_NOTES_FILTER',
  MATRIX_SEARCH_EXISTING: '[CASTING_PAX]MATRIX_SEARCH_EXISTING',
  MATRIX_DATA_SORT: '[CASTING_PAX]MATRIX_DATA_SORT',

  USER_MATRIX_SEARCH_EXISTING: '[CASTING_PAX]USER_MATRIX_SEARCH_EXISTING',
  MATRIX_USER_FILTER: '[CASTING_PAX]MATRIX_USER_FILTER',
  MATRIX_DAY_FILTER: '[CASTING_PAX]MATRIX_DAY_FILTER',

  SET_SELECTED_MATRIX_ITEM: '[CASTING_PAX]SET_SELECTED_MATRIX_ITEM',

  MATRIX_NEW_ATOMS_BEGIN: '[CASTING_PAX]MATRIX_NEW_ATOMS_BEGIN',
  MATRIX_NEW_ATOMS: asyncActionType('[CASTING_PAX]MATRIX_NEW_ATOMS'),

  MATRIX_UPDATE_TIMING_CALL: '[CASTING_PAX]MATRIX_UPDATE_TIMING_CALL',
  MATRIX_UPDATE_TIMING_CALL_BACK: '[CASTING_PAX]MATRIX_UPDATE_TIMING_CALL_BACK',

  MATRIX_UPDATE_ITEM_CALL: '[CASTING_PAX]MATRIX_UPDATE_ITEM_CALL',

  CHANGE_MATRIX_ATOMS_CALL: '[CASTING_PAX]CHANGE_MATRIX_ATOMS_CALL',
  CHANGE_MATRIX_ATOMS_UPDATE_CALL: '[CASTING_PAX]CHANGE_MATRIX_ATOMS_UPDATE_CALL',

  MATRIX_COORDINATORS: '[CASTING_PAX]MATRIX_COORDINATORS',
  MATRIX_ADD_COORDINATOR_BEGIN: '[CASTING_PAX]MATRIX_ADD_COORDINATOR_BEGIN',
  MATRIX_ADD_COORDINATOR: asyncActionType('[CASTING_PAX]MATRIX_ADD_COORDINATOR'),

  UPLOAD_CALL_SHEET_BEGIN: '[CASTING_PAX]UPLOAD_CALL_SHEET_BEGIN',
  UPLOAD_CALL_SHEET: asyncActionType('[CASTING_PAX]UPLOAD_CALL_SHEET'),

  REMOVE_CALL_SHEET_BEGIN: '[CASTING_PAX]REMOVE_CALL_SHEET_BEGIN',
  REMOVE_CALL_SHEET: asyncActionType('[CASTING_PAX]REMOVE_CALL_SHEET'),
  RESET_COUNTER_CALL_SHEET: '[CASTING_PAX]RESET_COUNTER_CALL_SHEET',

  UDPATE_MATRIX_STATUS_BEGIN: '[CASTING_PAX]UDPATE_MATRIX_STATUS_BEGIN',
  UDPATE_MATRIX_STATUS: asyncActionType('[CASTING_PAX]UDPATE_MATRIX_STATUS'),

  GET_MATRIX_USERS_BEGIN: '[CASTING_PAX]GET_MATRIX_USERS_BEGIN',
  GET_MATRIX_USERS: asyncActionType('[CASTING_PAX]GET_MATRIX_USERS'),

  SEND_CALL_SHEET_EMAIL_BEGIN: '[CASTING_PAX]SEND_CALL_SHEET_EMAIL_BEGIN',
  SEND_CALL_SHEET_EMAIL: asyncActionType('[CASTING_PAX]SEND_CALL_SHEET_EMAIL'),

  VALIDATE_CONFIRM_CALL_SHEET_BEGIN: '[CASTING_PAX]VALIDATE_CONFIRM_CALL_SHEET_BEGIN',
  VALIDATE_CONFIRM_CALL_SHEET: asyncActionType('[CASTING_PAX]VALIDATE_CONFIRM_CALL_SHEET'),

  SHOW_COORDINATOR_INVITATION_BEGIN: '[CASTING_PAX]SHOW_COORDINATOR_INVITATION_BEGIN',
  SHOW_COORDINATOR_INVITATION: asyncActionType('[CASTING_PAX]SHOW_COORDINATOR_INVITATION'),

  REMOVE_USER_SHOW_ACCESS: '[CASTING_PAX]REMOVE_USER_SHOW_ACCESS',

  SET_PERFORMER_TAB: '[CASTING_PAX]SET_PERFORMER_TAB',

  GET_PRODUCER_MATRIX_BEGIN: '[CASTING_PAX]GET_PRODUCER_MATRIX_BEGIN',
  GET_PRODUCER_MATRIX: asyncActionType('[CASTING_PAX]GET_PRODUCER_MATRIX'),

  GET_PAYROLL_USER_BEGIN: '[CASTING_PAX]GET_PAYROLL_USER_BEGIN',
  GET_PAYROLL_USER: asyncActionType('[CASTING_PAX]GET_PAYROLL_USER'),

  CLEAR_BOOKING_STATS: '[CASTING_PAX]CLEAR_BOOKING_STATS',
  GET_BOOKING_STATS_BEGIN: '[CASTING_PAX]GET_BOOKING_STATS_BEGIN',
  GET_BOOKING_STATS: asyncActionType('[CASTING_PAX]GET_BOOKING_STATS'),

  GET_DAILY_COST_BEGIN: '[CASTING_PAX]GET_DAILY_COST_BEGIN',
  GET_DAILY_COST: asyncActionType('[CASTING_PAX]GET_DAILY_COST'),

  GET_SHOW_USER_STATS_BEGIN: '[CASTING_PAX]GET_SHOW_USER_STATS_BEGIN',
  GET_SHOW_USER_STATS: asyncActionType('[CASTING_PAX]GET_SHOW_USER_STATS'),

  CHANGE_USER_ROLE_BEGIN: '[CASTING_PAX]CHANGE_USER_ROLE_BEGIN',
  CHANGE_USER_ROLE: asyncActionType('[CASTING_PAX]CHANGE_USER_ROLE'),

  COPY_PERFORMER: '[CASTING_PAX]COPY_PERFORMER',

  DATE_SELECTION_FOR_JOB_POST: '[CASTING_PAX]DATE_SELECTION_FOR_JOB_POST',

  GET_CARD_DETAILS_BEGIN: '[CASTING_PAX]GET_CARD_DETAILS_BEGIN',
  GET_CARD_DETAILS: asyncActionType('[CASTING_PAX]GET_CARD_DETAILS'),

  SAVE_CARD_DATA_BEGIN: '[CASTING_PAX]SAVE_CARD_DATA_BEGIN',
  SAVE_CARD_DATA: asyncActionType('[CASTING_PAX]SAVE_CARD_DATA'),
  CLEAR_CARD_DATA_ERROR: '[CASTING_PAX]CLEAR_CARD_DATA_ERROR',

  COPY_PERFORMER_FROM_ABOVE: '[CASTING_PAX]COPY_PERFORMER_FROM_ABOVE',
  MATRIX_COPY_ITEM_BEGIN: '[CASTING_PAX]MATRIX_COPY_ITEM_BEGIN',
  MATRIX_COPY_ITEM: asyncActionType('[CASTING_PAX]MATRIX_COPY_ITEM'),

  UPDATE_SELECTED_PERFORMER_CALENDAR: '[CASTING_PAX]UPDATE_SELECTED_PERFORMER_CALENDAR',
  UPDATE_PERFORMER_CALEDNAR_ON_JOB_POST: '[CASTING_PAX]UPDATE_PERFORMER_CALEDNAR_ON_JOB_POST',

  SEND_BG_SIZE_CARD_BEGIN: '[CASTING_PAX]SEND_BG_SIZE_CARD_BEGIN',
  SEND_BG_SIZE_CARD: asyncActionType('[CASTING_PAX]SEND_BG_SIZE_CARD'),

  SEND_BREAKDOWN_BEGIN: '[CASTING_PAX]SEND_BREAKDOWN_BEGIN',
  SEND_BREAKDOWN: asyncActionType('[CASTING_PAX]SEND_BREAKDOWN'),

  SET_NOTIFICATION_FOR_SETTING: '[CASTING_PAX]SET_NOTIFICATION_FOR_SETTING',
  NOTIFICATION_PRIVACY_STATUS: asyncActionType('[CASTING_PAX]NOTIFICATION_PRIVACY_STATUS'),

  GET_PERFORMER_RATE_BEGIN: '[CASTING_PAX]GET_PERFORMER_RATE_BEGIN',
  GET_PERFORMER_RATE: asyncActionType('[CASTING_PAX]GET_PERFORMER_RATE'),

  GET_PERFORMER_CUSTOM_RATE_BEGIN: '[CASTING_PAX]GET_PERFORMER_CUSTOM_RATE_BEGIN',
  GET_PERFORMER_CUSTOM_RATE: asyncActionType('[CASTING_PAX]GET_PERFORMER_CUSTOM_RATE'),

  GET_PERFORMER_VOUCHER_BEGIN: '[CASTING_PAX]GET_PERFORMER_VOUCHER_BEGIN',
  GET_PERFORMER_VOUCHER: asyncActionType('[CASTING_PAX]GET_PERFORMER_VOUCHER'),

  SAVE_PERFORMER_VOUCHER_BEGIN: '[CASTING_PAX]SAVE_PERFORMER_VOUCHER_BEGIN',
  SAVE_PERFORMER_VOUCHER: asyncActionType('[CASTING_PAX]SAVE_PERFORMER_VOUCHER'),

  CREATE_RATE_VALUES_BEGIN: '[CASTING_PAX]CREATE_RATE_VALUES_BEGIN',
  CREATE_RATE_VALUES: asyncActionType('[CASTING_PAX]CREATE_RATE_VALUES'),

  VIEW_RATE_TITLE_BEGIN: '[CASTING_PAX]VIEW_RATE_TITLE_BEGIN',
  UPDATE_RATE_TITLE_BEGIN: '[CASTING_PAX]UPDATE_RATE_TITLE_BEGIN',
  UPDATE_RATE_TITLE: asyncActionType('[CASTING_PAX]UPDATE_RATE_TITLE'),
  UPDATE_RATE_VALUES_BEGIN: '[CASTING_PAX]UPDATE_RATE_VALUES_BEGIN',
  UPDATE_RATE_VALUES: asyncActionType('[CASTING_PAX]UPDATE_RATE_VALUES'),

  VIEW_RATE_VALUES_BEGIN: '[CASTING_PAX]VIEW_RATE_VALUES_BEGIN',
  VIEW_RATE_VALUES: asyncActionType('[CASTING_PAX]VIEW_RATE_VALUES'),

  UPDATE_VOUCHER_STATUS_BEGIN: '[CASTING_PAX]UPDATE_VOUCHER_STATUS_BEGIN',
  UPDATE_VOUCHER_STATUS: asyncActionType('[CASTING_PAX]UPDATE_VOUCHER_STATUS'),

  CREATE_VOUCHER_BEGIN: '[CASTING_PAX]CREATE_VOUCHER_BEGIN',
  CREATE_VOUCHER: asyncActionType('[CASTING_PAX]CREATE_VOUCHER'),

  UPDATE_PERFORMER_VOUCHER_STATUS_BEGIN: '[CASTING_PAX]UPDATE_PERFORMER_VOUCHER_STATUS_BEGIN',
  UPDATE_PERFORMER_VOUCHER_STATUS: asyncActionType('[CASTING_PAX]UPDATE_PERFORMER_VOUCHER_STATUS'),
};

export const FETCH_STATUS = {
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export default {
  ...FILTER_ACTIONS,
};

export const NotificationNewJobRequest = 1;
export const NotificationJobAccept = 2;
export const NotificationJobReject = 3;
export const NotificationJobCancel = 4;
export const NotificationNewKey = 5;
export const NotificationNewJob = 6;
export const NotificationDirectorOnNewRequest = 7;
export const NotificationShowAddUser = 8;
export const NotificationJobRemove = 9;
export const NotificationMatrixDataCopy = 10;
export const NotificationGeneral = 11;
export const NotificationShowAccess = 12;
export const NotificationShowAccessAccepted = 13;
export const NotificationJobAcceptByDirector = 14;
export const NotificationNewShow = 15;
export const NotificationCloseJob = 16;
export const NotificationDeleteJob = 17;

export const FreeSubscrption = '609a5f810e6ee514507fa963';
export const FreeDays = 1;

export const ProfileKey = 'cppv';

export const JOB_STATUS_NEW = 'new';
export const JOB_STATUS_PENDING = 'pending';
export const JOB_STATUS_REQUESTED = 'requested';
export const JOB_STATUS_BOOKED = 'booked';
export const JOB_STATUS_CANCELLED = 'cancelled';
export const CALENDAR_STATUS_NOT_AVAILABLE = 'notavailable';
export const STATUS_REJECTED = 'rejected';
export const STATUS_CONFIRMED = 'confirmed';
export const TYPE_MATRIX = 'matrix';

export const PERMISSION_LEVEL_PD = 1;
export const PERMISSION_LEVEL_DC = 2;
export const PERMISSION_LEVEL_AC = 3;
export const PERMISSION_LEVEL_AD = 4;
export const PERMISSION_LEVEL_NA = 10;

export const SUBSCRIPTION_PRO = '609a5f810e6ee514507fa965';

export const SCREEN_SIZE_MOBILE: ScreenSize = 'MOBILE';
export const SCREEN_SIZE_TABLET: ScreenSize = 'TABLET';
export const SCREEN_SIZE_MEDIUM_TABLET: ScreenSize = 'MEDIUMTABLET';
export const SCREEN_SIZE_DESKTOP: ScreenSize = 'DESKTOP';
export const SCREEN_SIZE_LAPTOP: ScreenSize = 'LAPTOP';

export const ROLE_DIRECTOR: string = 'DIRECTOR';
export const ROLE_PRODUCTION: string = 'PRODUCTION';
export const ROLE_COORDINATOR: string = 'COORDINATOR';
export const ROLE_PERFORMER: string = 'PERFORMER';
export const ROLE_PAYROLL: string = 'PAYROLL';
export const SHOW_FROM_NOTIFICATION: string = 'notification';
export const SHOW_FROM_NAV_BAR: string = 'navbar';

export const ROLE_DIRECTOR_ID: string = '60671838f9d2b991014963f8';
export const ROLE_PRODUCTION_ID: string = '606717f8f9d2b991014963f7';
export const ROLE_PAYROLL_ID: string = '62d695b477cc9b1e19827c19';

export const SCREEN_WIDTH: string = 'screenWidth';

export const IMAGE_PATH_TYPE = {
  PROFILE: 'profile',
  SHOW: 'show',
  ATTACHMENTS: 'attachments',
  CALLSHEET: 'callsheet',
};

export const LOADER_STATUS = {
  NA: -1,
  PENDING: 1,
  COMPLETED: 0,
};

export const UNIT_FORMAT = {
  INCH: '"',
  LBS: 'lbs',
  KG: 'kg',
};

export const TOAST_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const MESSAGE_BOX_TYPE = {
  SUCCESS_EXPIRE: 1,
};

export const TOAST_IDS = {
  CANCEL_BOOKING_ID: 1,
  CALL_SHEET_SEND_SUCCESS: 2,
  CALL_SHEEET_SEND_ERROR: 3,
  CASTING_REQUEST_SEND_SUCCESS: 4,
  CASTING_REQUEST_SEND_FAIL: 5,
  CREATE_SHOW_FAIL: 6,
  CREATE_JOB_FAIL: 7,
  MATRIX_DATA_UPDATE_FAIL: 8,
  CREATE_EMAIL_TEMPLATE: 9,
  DELETE_ACCOUNT_ERROR: 10,
  APPLY_FOR_JOB_ERROR: 11,
  EMAIL_NOT_VALID_ERROR: 12,
  GENERAL_SUCCESS_MESSAGE: 13,
  GENERAL_ERROR_MESSAGE: 14,
  NOT_AVAILABLE_BOOKING: 15,
  CREATE_REQUEST_MESSAGE: 16,
  CREATE_JOB_SUCCESS: 17,
  CLOSE_OR_DELETE_JOB_SUCCESS: 18,
  CLOSE_OR_DELETE_JOB_ERROR: 19,
  CALL_SHEET_FILE_ERROR: 20,
  UPLOAD_RENTAL_IMAGE: 21,
  IMAGE_SIZE_EXCEED: 22,
  REMOVE_RENTAL_IMAGE: 23,
  CHANGE_RENTAL_TYPE: 24,
  BG_SIZE_CARD_SUCCESS: 25,
  BG_SIZE_CARD_ERROR: 26,
  BREAKDOWN_MATRIX_SUCCESS: 27,
  BREAKDOWN_MATRIX_ERROR: 28,
  CREATE_PERFORMER_VOUCHER_SUCCESS: 29,
  CREATE_PERFORMER_VOUCHER_ERROR: 30,
  CREATE_CUSTOM_RATE_SUCCESS: 31,
  SHARE_CUSTOM_RATE_SUCCESS: 32,
  UPLOAD_BG_ALBUM_IMAGE_ERROR: 33,
  UPDATE_PERFORMER_VOUCHER__STATUS_SUCCESS: 24,
};

export const PERFORMER_TAB = {
  FIND_PERFORMER: '-1',
  SUGGESTED_BG: '0',
  SUBMITTED_REQUEST: '1',
};

export const SHOW_JOB_STATUS = {
  PUBLIC: 'public',
  INVITE: 'invite',
  CLOSE: 'close',
  DELETE: 'delete',
  DRAFT: 'draft',
};

export const JOB_CANCEL_SLUG = {
  RCBP: 'rcbp',
  JCBP: 'jcbp',
  RCBD: 'rcbd',
  JCBD: 'jcbd',
};

export const IMAGE_BASE_URL = 'https://www.castingpax.com/api/static/images/';
