import IShowData from 'components/Payroll/common/chart/interface/showData.interface';
import { EmailTemplate, EmailTemplateList } from 'modules/general/general.type';
import { BookingRequest, CalendarList } from 'modules/jobs/types';
import {
  IAddCoordinator,
  IAddShowAccess,
  IBGCInvitation,
  IBgSizeCardParams,
  IBreakdownParams,
  ICancelRequestParams,
  IChangeAtoms,
  IChangeRoleParams,
  ICopyMatrixItem,
  ICopyMatrixItemResponse,
  IDailyCost,
  IEmailTemplateParams,
  IFindPerformerFilter,
  IMatrixParams,
  INewAtomsParam,
  IPerformerSearchParam,
  IProfileFileParam,
  IRemoveCallSheetFile,
  ISendJobRequestParam,
  ISetMatrixEmailTemplate,
  IShowId,
  IUpdateMatrixItem,
  IUpdateMatrixTime,
  IValidateCallSheet,
  IBreakdownPdfParams,
} from 'modules/params/param.type';
import ApiResponse from 'modules/response/response.types';
import {
  CloseOrDeleteShowJob,
  DateJobRequestParams,
  MatrixAtoms,
  MatrixRedirect,
  MatrixType,
  PerformerItem,
  Show,
  ShowCreateParams,
  ShowDayJob,
  ShowDayJobList,
  ShowList,
  RateList,
  ShowTeamMember,
} from 'modules/Show/show.types';
import { dataUpdateMatrixList, User, UserList } from 'modules/user/types';
import ACTIONS, { FETCH_STATUS } from 'store/castingPax.constants';

export const showListing = {
  pending: {
    type: ACTIONS.SHOW_LISTING_API.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: ShowList) => ({
    type: ACTIONS.SHOW_LISTING_API.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SHOW_LISTING_API.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const showHistory = {
  pending: {
    type: ACTIONS.SHOW_HISTORY_API.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: ShowList) => ({
    type: ACTIONS.SHOW_HISTORY_API.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SHOW_HISTORY_API.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const createShow = {
  pending: {
    type: ACTIONS.CREATE_SHOW.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: Show) => ({
    type: ACTIONS.CREATE_SHOW.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.CREATE_SHOW.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const showAccessRequest = {
  pending: {
    type: ACTIONS.SHOW_ACCESS_REQUEST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: ShowTeamMember) => ({
    type: ACTIONS.SHOW_ACCESS_REQUEST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SHOW_ACCESS_REQUEST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const showAccessRequestBegin = (payload: IAddShowAccess) => ({
  type: ACTIONS.SHOW_ACCESS_REQUEST_BEGIN,
  payload,
});

export const showImageUpload = {
  pending: {
    type: ACTIONS.SHOW_IMAGE_UPLOAD.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: string) => ({
    type: ACTIONS.SHOW_IMAGE_UPLOAD.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SHOW_IMAGE_UPLOAD.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const showImageUploadBegin = (payload: IProfileFileParam) => ({
  type: ACTIONS.SHOW_IMAGE_UPLOAD_BEING,
  payload,
});

export const resetShowAccessRequest = () => ({
  type: ACTIONS.SHOW_ACCESS_REQUEST_BEGIN,
  payload: {},
});

export const showSelectedDateJob = {
  pending: {
    type: ACTIONS.SET_SHOW_SELECTED_DATE_JOB.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: ShowDayJobList, payload: DateJobRequestParams) => ({
    type: ACTIONS.SET_SHOW_SELECTED_DATE_JOB.SUCCESS,
    data,
    payload,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SET_SHOW_SELECTED_DATE_JOB.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const errorNotes = {
  pending: {
    type: ACTIONS.ERROR_NOTES_API.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any[], payload: any) => ({
    type: ACTIONS.ERROR_NOTES_API.SUCCESS,
    data,
    payload,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.ERROR_NOTES_API.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const notesReview = {
  pending: {
    type: ACTIONS.NOTES_REVIEW_API.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any[], payload: any) => ({
    type: ACTIONS.NOTES_REVIEW_API.SUCCESS,
    data,
    payload,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.NOTES_REVIEW_API.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const searchPerformer = {
  pending: {
    type: ACTIONS.SET_PERFORMER_SEARCH.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.SET_PERFORMER_SEARCH.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SET_PERFORMER_SEARCH.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const searchPerformerRequest = (payload: IPerformerSearchParam) => ({
  type: ACTIONS.SET_PERFORMER_SEARCH_BEGIN,
  payload,
});

export const getShowListingRequest = () => ({
  type: ACTIONS.FETCH_SHOW_LISTING_BEGIN,
});

export const getShowHistoryRequest = () => ({
  type: ACTIONS.FETCH_SHOW_HISTORY_BEGIN,
});

export const getShowCostBudgetRequest = () => ({
  type: ACTIONS.FETCH_SHOW_COST_BUDGET_BEGIN,
});

export const getErrorNotesData = () => ({
  type: ACTIONS.FETCH_ERROR_NOTES_BEGIN,
});

export const getNotesReviewData = () => ({
  type: ACTIONS.FETCH_NOTES_REVIEW_BEGIN,
});

export const getRecentActionsData = () => ({
  type: ACTIONS.FETCH_RECENT_ACTIONS_BEGIN,
});

export const setShowListing = (state: any) => ({
  type: ACTIONS.SET_SHOW_LISTING,
  data: state,
});

export const setSelectShow = (id: string) => ({
  type: ACTIONS.SHOW_SELECTION_CHANGE,
  data: id,
});

export const getCreateShowRequest = (show: ShowCreateParams) => ({
  type: ACTIONS.CREATE_SHOW_BEGIN,
  payload: show,
});

export const getUpdateShowRequest = (show: ShowCreateParams) => ({
  type: ACTIONS.UPDATE_SHOW_BEGIN,
  payload: show,
});

export const showEditRequest = (show: Show) => ({
  type: ACTIONS.CALL_SHOW_EDIT,
  data: show,
});

export const showDetailRequest = (show: Show) => ({
  type: ACTIONS.CALL_SHOW_DETAIL,
  data: show,
});

export const getShowDayJobRequest = (show: ShowDayJobList) => ({
  type: ACTIONS.SET_SHOW_DAY_JOB_LIST,
  data: show,
});

export const createJob = {
  pending: {
    type: ACTIONS.CREATE_JOB.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: ShowDayJob) => ({
    type: ACTIONS.CREATE_JOB.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.CREATE_JOB.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const createRoleRequest = (job: ShowDayJob) => ({
  type: ACTIONS.CREATE_JOB,
  payload: job,
});

export const setSelectedJob = (job: ShowDayJob) => ({
  type: ACTIONS.SET_SELETED_JOB,
  data: job,
});

export const recallSelectedJobDate = () => ({
  type: ACTIONS.RECALL_SELECTED_JOB_DATE,
});

export const setDatesForFitler = (data: string[]) => ({
  type: ACTIONS.SET_SELETED_JOB_DATES,
  data,
});

export const createJobBegin = (job: ShowDayJob) => ({
  type: ACTIONS.CREATE_JOB_BEGIN,
  payload: job,
});

export const setShowSelectedDate = (data: DateJobRequestParams) => ({
  type: ACTIONS.SET_SHOW_SELECTED_DATE_BEGIN,
  payload: data,
});

export const clearSearchResult = () => ({
  type: ACTIONS.SET_CLEAR_SEARCH_RESULT,
});

export const emailTemplates = {
  pending: {
    type: ACTIONS.EMAIL_TEMPLATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: EmailTemplateList) => ({
    type: ACTIONS.EMAIL_TEMPLATE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.EMAIL_TEMPLATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
export const getEmailTemplateRequest = (payload: IEmailTemplateParams) => ({
  type: ACTIONS.EMAIL_TEMPLATE_BEGIN,
  payload,
});

export const saveEmailTemplate = {
  pending: {
    type: ACTIONS.SAVE_MATRIX_EMAIL_TEMPLATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: EmailTemplate) => ({
    type: ACTIONS.SAVE_MATRIX_EMAIL_TEMPLATE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SAVE_MATRIX_EMAIL_TEMPLATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
export const saveEmailTemplateBegin = (payload: IEmailTemplateParams) => ({
  type: ACTIONS.SAVE_MATRIX_EMAIL_TEMPLATE_BEGIN,
  payload,
});

export const updatePrelimEmailTemplateId = (data: any) => ({
  type: ACTIONS.UPDATE_PRELIM_EMAIL_TEMPLATE_ID,
  data,
});

export const clearSelectedJobListing = () => ({
  type: ACTIONS.CLEAR_SHOW_SELECTED_DATE_JOB,
  data: {},
});

export const setPerformerViewMode = (isFullView: boolean) => ({
  type: ACTIONS.SET_PERFORMER_VIEW_MODE,
  data: isFullView,
});

export const createEmptyShowJob = () => {
  const showJob: ShowDayJob = {
    id: '-1',
    workingDayId: [],
    title: '',
    description: '',
    date: '',
    startDate: '',
    location: { id: '-1', label: 'Select', value: 'Select', type: 'location' },
    locationId: '',
    rate: { id: '-1', label: 'Select', value: 'Select', rate: 0, code: 'Select', title: 'Select' },
    rateId: '',
    minHeight: '',
    maxHeight: '',
    ethnicity: '',
    clothingOption: '',
    bodyRequirements: '',
    remarks: '',
    additionalInfo: '',
    createdBy: '',
    status: '',
    isActive: true,
    noPerformerRequired: 1,
    dates: [],
    isSaveForLater: false,
  };
  return {
    type: ACTIONS.CREATE_EMPTY_SHOW_JOB,
    data: showJob,
  };
};

export const showDetail = {
  pending: {
    type: ACTIONS.FETCH_SHOW_DETAIL.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: Show, showType: string) => ({
    type: ACTIONS.FETCH_SHOW_DETAIL.SUCCESS,
    data,
    showType,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.FETCH_SHOW_DETAIL.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const showDetailBegin = (payload: IShowId, showType: string) => ({
  type: ACTIONS.FETCH_SHOW_DETAIL_BEGIN,
  payload,
  showType,
});

export const matrixSelectedDate = (data: string) => ({
  type: ACTIONS.MATRIX_SELECT_DATE,
  data,
});

export const getMatrixDataBegin = (payload: IMatrixParams) => ({
  type: ACTIONS.MATRIX_DATA_BEGIN,
  payload,
});

export const searchExistingMatrix = (data: String) => ({
  type: ACTIONS.MATRIX_SEARCH_EXISTING,
  data,
});

export const searchUserMangementMatrix = (data: String) => ({
  type: ACTIONS.USER_MATRIX_SEARCH_EXISTING,
  data,
});

export const filterUserMangementMatrix = (data: String) => ({
  type: ACTIONS.MATRIX_USER_FILTER,
  data,
});

export const filterDayMatrix = (data: String) => ({
  type: ACTIONS.MATRIX_DAY_FILTER,
  data,
});

export const rateFilterExistingMatrix = (data: String) => ({
  type: ACTIONS.MATRIX_RATE_FILTER,
  data,
});

export const hideFilterExistingMatrix = (data: String) => ({
  type: ACTIONS.MATRIX_HIDE_FILTER,
  data,
});

export const errorNotesFilterExistingMatrix = (data: String) => ({
  type: ACTIONS.MATRIX_ERROR_NOTES_FILTER,
  data,
});

export const sortMatrixData = (data: String) => ({
  type: ACTIONS.MATRIX_DATA_SORT,
  data,
});

export const fetchMatrixData = {
  pending: (data: IMatrixParams) => ({
    type: ACTIONS.MATRIX_DATA.PENDING,
    data,
    status: FETCH_STATUS.LOADING,
  }),
  success: (data: ApiResponse) => ({
    type: ACTIONS.MATRIX_DATA.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.MATRIX_DATA.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const fetchMatrixPerformerBegin = (payload: IMatrixParams) => ({
  type: ACTIONS.MATRIX_PERFORMER_DATA_BEGIN,
  payload,
});

export const fetchMatrixPerformer = {
  pending: {
    type: ACTIONS.MATRIX_PERFORMER_DATA.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: PerformerItem[], payload: IMatrixParams) => ({
    type: ACTIONS.MATRIX_PERFORMER_DATA.SUCCESS,
    data,
    payload,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.MATRIX_PERFORMER_DATA.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const breakdownBegin = (payload: IBreakdownPdfParams) => ({
  type: ACTIONS.BREAKDOWN_BEGIN,
  payload,
});

export const breakdown = {
  pending: (data: IBreakdownPdfParams) => ({
    type: ACTIONS.BREAKDOWN.PENDING,
    data,
    status: FETCH_STATUS.LOADING,
  }),
  success: (data: ApiResponse) => ({
    type: ACTIONS.BREAKDOWN.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.BREAKDOWN.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const fetchShowHistory = {
  pending: {
    type: ACTIONS.SHOW_HISTORY_API.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: IShowData[], payload: IMatrixParams) => ({
    type: ACTIONS.SHOW_HISTORY_API.SUCCESS,
    data,
    payload,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SHOW_HISTORY_API.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const fetchRecentActions = {
  pending: {
    type: ACTIONS.RECENT_ACTIONS_API.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: IShowData[], payload: IMatrixParams) => ({
    type: ACTIONS.RECENT_ACTIONS_API.SUCCESS,
    data,
    payload,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.RECENT_ACTIONS_API.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const fetchCostBudgetData = {
  pending: {
    type: ACTIONS.SHOW_COST_BUDGET_API.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: IShowData[], payload: IMatrixParams) => ({
    type: ACTIONS.SHOW_COST_BUDGET_API.SUCCESS,
    data,
    payload,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SHOW_COST_BUDGET_API.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const newAtomsBegin = (payload: INewAtomsParam) => ({
  type: ACTIONS.MATRIX_NEW_ATOMS_BEGIN,
  payload,
});

export const saveNewAtoms = {
  pending: {
    type: ACTIONS.MATRIX_NEW_ATOMS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: MatrixAtoms, payload: INewAtomsParam) => ({
    type: ACTIONS.MATRIX_NEW_ATOMS.SUCCESS,
    data,
    payload,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.MATRIX_NEW_ATOMS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const saveCustomRate = {
  pending: {
    type: ACTIONS.ADD_CUSTOM_RATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any, payload: any) => ({
    type: ACTIONS.ADD_CUSTOM_RATE.SUCCESS,
    data,
    payload,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.ADD_CUSTOM_RATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
export const shareCustomRate = {
  pending: {
    type: ACTIONS.SHARE_CUSTOM_RATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any, payload: any) => ({
    type: ACTIONS.SHARE_CUSTOM_RATE.SUCCESS,
    data,
    payload,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SHARE_CUSTOM_RATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const manageCoordinatorBegin = (payload: IAddCoordinator, data: User) => ({
  type: ACTIONS.MATRIX_ADD_COORDINATOR_BEGIN,
  payload,
  data,
});

export const manageCoordinator = {
  pending: {
    type: ACTIONS.MATRIX_ADD_COORDINATOR.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: User, payload: IAddCoordinator) => ({
    type: ACTIONS.MATRIX_ADD_COORDINATOR.SUCCESS,
    data,
    payload,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.MATRIX_ADD_COORDINATOR.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const setMatrixCoordinator = (data: UserList, invitation: string[]) => ({
  type: ACTIONS.MATRIX_COORDINATORS,
  data,
  invitation,
});

export const setMatrixUpdateTiming = (payload: IUpdateMatrixTime[]) => ({
  type: ACTIONS.MATRIX_UPDATE_TIMING_CALL,
  payload,
});

export const shareCustomRateWithUser = (payload: any) => ({
  type: ACTIONS.SHARE_CUSTOM_RATE_BEGIN,
  payload,
});

export const updateMatrixData = (data: dataUpdateMatrixList) => ({
  type: ACTIONS.MATRIX_UPDATE_TIMING_CALL_BACK,
  data,
});

export const setMatrixUpdateItem = (payload: IUpdateMatrixItem[]) => ({
  type: ACTIONS.MATRIX_UPDATE_ITEM_CALL,
  payload,
});

export const changeMatrixAtomsCall = (payload: IChangeAtoms, workingId: string) => ({
  type: ACTIONS.CHANGE_MATRIX_ATOMS_CALL,
  payload,
  workingId,
});

export const changeMatrixAtoms = (data: IChangeAtoms, workingId: string) => ({
  type: ACTIONS.CHANGE_MATRIX_ATOMS_UPDATE_CALL,
  data,
  workingId,
});

export const uploadCallSheet = {
  pending: {
    type: ACTIONS.UPLOAD_CALL_SHEET.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: string, payload: string) => ({
    type: ACTIONS.UPLOAD_CALL_SHEET.SUCCESS,
    data,
    payload,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.UPLOAD_CALL_SHEET.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const uploadCallSheetBegin = (payload: IProfileFileParam, workingDayId: string) => ({
  type: ACTIONS.UPLOAD_CALL_SHEET_BEGIN,
  payload,
  workingDayId,
});

export const removeCallSheetBegin = (payload: IRemoveCallSheetFile) => ({
  type: ACTIONS.REMOVE_CALL_SHEET_BEGIN,
  payload,
});

export const removeCallSheet = {
  pending: {
    type: ACTIONS.REMOVE_CALL_SHEET.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.REMOVE_CALL_SHEET.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.REMOVE_CALL_SHEET.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const resetCounterCallSheet = () => ({
  type: ACTIONS.RESET_COUNTER_CALL_SHEET,
});

export const setSelectedMatrix = (data: MatrixType) => ({
  type: ACTIONS.SET_SELECTED_MATRIX_ITEM,
  data,
});

export const setMatrixStatus = {
  pending: {
    type: ACTIONS.UDPATE_MATRIX_STATUS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: IMatrixParams) => ({
    type: ACTIONS.UDPATE_MATRIX_STATUS.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.UDPATE_MATRIX_STATUS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const setMatrixStatusBegin = (payload: IMatrixParams) => ({
  type: ACTIONS.UDPATE_MATRIX_STATUS_BEGIN,
  payload,
});

export const fetchShowUsers = {
  pending: {
    type: ACTIONS.GET_MATRIX_USERS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: ApiResponse, payload: IMatrixParams) => ({
    type: ACTIONS.GET_MATRIX_USERS.SUCCESS,
    data,
    payload,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.GET_MATRIX_USERS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const fetchShowUsersBegin = (payload: IMatrixParams) => ({
  type: ACTIONS.GET_MATRIX_USERS_BEGIN,
  payload,
});

export const removeShowAccess = (data: ShowTeamMember) => ({
  type: ACTIONS.REMOVE_USER_SHOW_ACCESS,
  data,
});

export const fetchProducerMatrix = {
  pending: {
    type: ACTIONS.GET_PRODUCER_MATRIX.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: ApiResponse, payload: IMatrixParams) => ({
    type: ACTIONS.GET_PRODUCER_MATRIX.SUCCESS,
    data,
    payload,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.GET_PRODUCER_MATRIX.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
export const fetchPayRollUser = {
  pending: {
    type: ACTIONS.GET_PAYROLL_USER.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: ApiResponse, payload: any) => ({
    type: ACTIONS.GET_PAYROLL_USER.SUCCESS,
    data,
    payload,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.GET_PAYROLL_USER.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
export const fetchProducerMatrixBegin = (payload: IMatrixParams) => ({
  type: ACTIONS.GET_PRODUCER_MATRIX_BEGIN,
  payload,
});

export const fetchPayRollUserBegin = (payload: IMatrixParams) => ({
  type: ACTIONS.GET_PAYROLL_USER_BEGIN,
  payload,
});

export const fetchBookingStats = {
  pending: {
    type: ACTIONS.GET_BOOKING_STATS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any, payload: IMatrixParams) => ({
    type: ACTIONS.GET_BOOKING_STATS.SUCCESS,
    data,
    payload,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.GET_BOOKING_STATS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const fetchBookingStatsBegin = (payload: IMatrixParams) => ({
  type: ACTIONS.GET_BOOKING_STATS_BEGIN,
  payload,
});

export const fetchDailyCosts = {
  pending: {
    type: ACTIONS.GET_DAILY_COST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: ApiResponse) => ({
    type: ACTIONS.GET_DAILY_COST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.GET_DAILY_COST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const fetchDailyCostInvoices = (payload: IDailyCost) => ({
  type: ACTIONS.GET_DAILY_COST_BEGIN,
  payload,
});

export const clearShowStats = () => ({
  type: ACTIONS.CLEAR_BOOKING_STATS,
  payload: {},
});

export const fetchShowUserStats = {
  pending: {
    type: ACTIONS.GET_SHOW_USER_STATS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any, payload: IMatrixParams) => ({
    type: ACTIONS.GET_SHOW_USER_STATS.SUCCESS,
    data,
    payload,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.GET_SHOW_USER_STATS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const fetchShowUserStatsBegin = (payload: IMatrixParams) => ({
  type: ACTIONS.GET_SHOW_USER_STATS_BEGIN,
  payload,
});

export const changeUserRole = {
  pending: {
    type: ACTIONS.CHANGE_USER_ROLE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: IChangeRoleParams) => ({
    type: ACTIONS.CHANGE_USER_ROLE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.CHANGE_USER_ROLE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const changeUserRoleBegin = (payload: IChangeRoleParams) => ({
  type: ACTIONS.CHANGE_USER_ROLE_BEGIN,
  payload,
});

export const setMatrixRedirected = (data: MatrixRedirect) => ({
  type: ACTIONS.SET_MATRIX_REDIRECTED,
  data,
});

export const setShowRedirect = (data: string) => ({
  type: ACTIONS.SET_SHOW_REDIRECT,
  data,
});

export const clearMatrixRedirected = () => ({
  type: ACTIONS.CLEAR_MATRIX_REDIRECTED,
  data: {},
});

export const setJobPostDates = (data: string[], userId: string) => ({
  type: ACTIONS.DATE_SELECTION_FOR_JOB_POST,
  data,
  userId,
});

export const setCopyFromAboveData = (data: PerformerItem[]) => ({
  type: ACTIONS.COPY_PERFORMER_FROM_ABOVE,
  data,
});

export const copyMatrixItem = {
  pending: {
    type: ACTIONS.MATRIX_COPY_ITEM.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: ICopyMatrixItemResponse) => ({
    type: ACTIONS.MATRIX_COPY_ITEM.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.MATRIX_COPY_ITEM.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const copyMatrixItemBegin = (payload: ICopyMatrixItem) => ({
  type: ACTIONS.MATRIX_COPY_ITEM_BEGIN,
  payload,
});

export const updatePerformerCalendar = (data: ISendJobRequestParam, job: BookingRequest) => ({
  type: ACTIONS.UPDATE_PERFORMER_CALEDNAR_ON_JOB_POST,
  data,
  job,
});

export const processJobRequest = {
  pending: (payload: any) => ({
    type: ACTIONS.SET_JOB_BOOKING_PROCESS.PENDING,
    payload,
    status: FETCH_STATUS.LOADING,
  }),
  success: (data: ICancelRequestParams, bookingStatus: string) => ({
    type: ACTIONS.SET_JOB_BOOKING_PROCESS.SUCCESS,
    data,
    bookingStatus,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SET_JOB_BOOKING_PROCESS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
export const cancelJobRequestBegin = (payload: ICancelRequestParams) => ({
  type: ACTIONS.CANCEL_JOB_BOOKING_PROCESS_BEGIN,
  payload,
});

export const acceptJobRequestBegin = (payload: ICancelRequestParams) => ({
  type: ACTIONS.ACCEPT_JOB_BOOKING_PROCESS_BEGIN,
  payload,
});

export const updateCalendarOnCancelJob = (userId: string, data: CalendarList, job?: BookingRequest) => ({
  type: ACTIONS.UPDAET_PERFORMER_CALENDAR_LIST,
  userId,
  data,
  job,
});

export const setPerformerUpdatedData = (data: any) => ({
  type: ACTIONS.SET_PERFORMER_UPDATED_DETAILS_IN_BG,
  data,
});

export const clearJobErrorMessage = () => ({
  type: ACTIONS.CLEAR_JOB_ERROR_MESSAGE,
  data: '',
});

export const loadMoreBgPerformer = () => ({
  type: ACTIONS.LOAD_MORE_BG_PERFORMER,
  data: '',
});

export const sortPerformerResult = (data: number) => ({
  type: ACTIONS.SORT_PERFORMER_ORDER,
  data,
});

export const searchExistingPerformer = (data: IFindPerformerFilter) => ({
  type: ACTIONS.SEARCH_EXISTING_PERFORMER,
  data,
});

export const sendCallSheet = {
  pending: (payload: ISetMatrixEmailTemplate) => ({
    type: ACTIONS.SEND_CALL_SHEET_EMAIL.PENDING,
    payload,
    status: FETCH_STATUS.LOADING,
  }),
  success: (data: ISetMatrixEmailTemplate) => ({
    type: ACTIONS.SEND_CALL_SHEET_EMAIL.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SEND_CALL_SHEET_EMAIL.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
export const sendCallSheetBegin = (payload: ISetMatrixEmailTemplate) => ({
  type: ACTIONS.SEND_CALL_SHEET_EMAIL_BEGIN,
  payload,
});

export const showCoordinatorInvitation = {
  pending: {
    type: ACTIONS.SHOW_COORDINATOR_INVITATION.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: string[], payload: IBGCInvitation) => ({
    type: ACTIONS.SHOW_COORDINATOR_INVITATION.SUCCESS,
    data,
    payload,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SHOW_COORDINATOR_INVITATION.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
export const showCoordinatorInvitationBegin = (payload: IBGCInvitation) => ({
  type: ACTIONS.SHOW_COORDINATOR_INVITATION_BEGIN,
  payload,
});

export const setPerformerTab = (data: boolean) => ({
  type: ACTIONS.SET_PERFORMER_TAB,
  data,
});

export const closeOrDeleteJobBegin = (job: CloseOrDeleteShowJob) => ({
  type: ACTIONS.CLOSE_DELETE_JOB_BEGIN,
  payload: job,
});

export const removeShowJob = (jobId: string) => ({
  type: ACTIONS.REMOVE_SHOW_JOB,
  data: jobId,
});

export const closeOrDeleteJob = {
  pending: {
    type: ACTIONS.CLOSE_DELETE_JOB.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: ShowDayJob) => ({
    type: ACTIONS.CLOSE_DELETE_JOB.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.CLOSE_DELETE_JOB.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const validateConfirmCallSheetBegin = (data: IValidateCallSheet) => ({
  type: ACTIONS.VALIDATE_CONFIRM_CALL_SHEET_BEGIN,
  data,
});

export const validateConfirmCallSheet = {
  pending: {
    type: ACTIONS.SHOW_COORDINATOR_INVITATION.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: string[], payload: IBGCInvitation) => ({
    type: ACTIONS.SHOW_COORDINATOR_INVITATION.SUCCESS,
    data,
    payload,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SHOW_COORDINATOR_INVITATION.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const sendBgSizeCardBegin = (payload: IBgSizeCardParams) => ({
  type: ACTIONS.SEND_BG_SIZE_CARD_BEGIN,
  payload,
});

export const sendBgSizeCard = {
  pending: {
    type: ACTIONS.SEND_BG_SIZE_CARD.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: string) => ({
    type: ACTIONS.SEND_BG_SIZE_CARD.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SEND_BG_SIZE_CARD.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const sendBreakdownBegin = (payload: IBreakdownParams) => ({
  type: ACTIONS.SEND_BREAKDOWN_BEGIN,
  payload,
});

export const sendBreakdown = {
  pending: {
    type: ACTIONS.SEND_BREAKDOWN.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: string) => ({
    type: ACTIONS.SEND_BREAKDOWN.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SEND_BREAKDOWN.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getRateListRequest = () => ({
  type: ACTIONS.GET_PERFORMER_RATE_BEGIN,
});

export const getCustomRateListRequest = () => ({
  type: ACTIONS.GET_PERFORMER_CUSTOM_RATE_BEGIN,
});

export const addCustomRateRequest = (payload: any) => ({
  type: ACTIONS.ADD_CUSTOM_RATE_BEGIN,
  payload,
});

export const getRateList = {
  pending: {
    type: ACTIONS.GET_PERFORMER_RATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: RateList) => ({
    type: ACTIONS.GET_PERFORMER_RATE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.GET_PERFORMER_RATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getCustomRateList = {
  pending: {
    type: ACTIONS.GET_PERFORMER_CUSTOM_RATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: RateList) => ({
    type: ACTIONS.GET_PERFORMER_CUSTOM_RATE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.GET_PERFORMER_CUSTOM_RATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
