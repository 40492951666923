import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MatrixType, Show, ShowTopCalendarTypeList } from 'modules/Show/show.types';
import { fetchMatrixPerformerBegin, breakdownBegin } from 'store/actions/show.actions';
import { User } from 'modules/user/types';
import { getUser } from 'store/selector/auth.selector';
import { getSelectedShow } from 'store/selector/show.selector';
import { Spinner } from 'react-bootstrap';
import moment from 'moment-timezone';
import StyledShowInfo from '../../../styles/show/show-info/ShowInfo.styled';
import TopInfoTab from './TopInfoTab';
import BottomInfoTab from './BottomInfoTab';
import ShowInforGrid from '../show-info-grid/ShowInfoGrid';
import InvoicePDF from '../../Invoice';
import BreakDown from '../../BreakDown/index';

interface Props {
  fetchMatrixPerformer: Function;
  showCalendarDates: ShowTopCalendarTypeList;
  dayItem: MatrixType;
  user: User;
  date: string;
  callBack: Function;
  // grid: boolean;
  render: Function;
  key: any;
  prevElement: any;
  breakdown: Function;
}

const ShowInfo: React.FC<Props> = (props) => {
  const {
    dayItem,
    fetchMatrixPerformer,
    user,
    date,
    showCalendarDates,
    key,
    callBack,
    prevElement,
    render,
    breakdown,
  } = props;
  const [item, setItem] = useState<MatrixType>(dayItem);
  const [pendingValue, setPendingValue] = useState<any>();
  const [showGrid, setOpenGrid] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [pending, setPending] = useState<boolean>(false);
  const [isPrint, setIsPrint] = useState<boolean>(false);
  const performerArr: any = [];
  const handleChange = (e: any, data: any) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      performerArr.push(data);
      localStorage.setItem('performerArr', JSON.stringify(performerArr));
    } else {
      const index = performerArr.findIndex((obj: any) => obj._id === data._id);
      if (index > -1) {
        performerArr.splice(index, 1);
        localStorage.setItem('performerArr', JSON.stringify(performerArr));
      }
    }
  };
  useEffect(() => {
    setIsLoaded(true);
  });
  const printRef: any = useRef();
  useEffect(() => {
    setItem(dayItem);
    if (!item.performers || item.performers.length === 0) {
      loadPerformerData();
    }
    // if (render) setOpenGrid(true);
  }, [dayItem]);
  useEffect(() => {
    setItem(dayItem);
  }, [dayItem.atoms, dayItem.performers]);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isPrint) {
      const arr = JSON.parse(localStorage.getItem('performerArr') || '');
      return arr.forEach((p: any) => <InvoicePDF isDownload item={p} isLoading={setIsLoaded} />);
    }
  }, [isPrint]);
  const loadPerformerData = () => fetchMatrixPerformer({ workingDayId: item.id, id: user.id });
  const handleShowGrid = useCallback(() => {
    setOpenGrid(!showGrid);
    callBack(item.id, user.id);
  }, [showGrid]);
  const pendingPerformer = (value: boolean) => {
    setPending(value);
    if (value && item.performers) {
      const pendingPerformers = item.performers.filter((ele) => ele.status === 'pending');
      setPendingValue(pendingPerformers);
    }
  };
  const getDate = () => showCalendarDates.filter((i: any) => dayItem.id === i.id);
  const handleBreakDown = () => {
    breakdown({ id: dayItem.id });
  };

  if (isLoaded) {
    return (
      <StyledShowInfo>
        <TopInfoTab
          arrowColor="#4B4D67"
          handleShowGrid={handleShowGrid}
          showGrid={showGrid}
          show={dayItem.showId}
          title={dayItem.title}
          date={getDate().length > 0 ? getDate()[0].dayTitle : moment(dayItem.date).format('MMM DD')}
        />
        <BottomInfoTab
          dayItem={dayItem}
          printRef={printRef}
          prevElement={prevElement}
          pendingPerformer={pendingPerformer}
          pdfType="voucher"
          isPrint={isPrint}
          setIsPrint={setIsPrint}
          handleBreakDown={handleBreakDown}
        />
        {showGrid && (
          <ShowInforGrid
            performers={pending ? pendingValue : item.performers}
            refValue={printRef}
            key={key}
            render={render}
            handleChange={(event: any, data: any) => handleChange(event, data)}
          />
        )}
      </StyledShowInfo>
    );
  }
  return (
    <>
      <Spinner as="span" animation="border" size="sm" className="gridLoader" role="status" aria-hidden="true" />
    </>
  );
};

const mapStateToProps = (state: Show) => {
  const user: User = getUser(state);
  const show: Show = getSelectedShow(state);
  return { user, show };
};

const mapDispatchToProps = {
  fetchMatrixPerformer: fetchMatrixPerformerBegin,
  breakdown: breakdownBegin,
};
export default connect(mapStateToProps, mapDispatchToProps)(ShowInfo);
