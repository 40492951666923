import React, { FunctionComponent, useEffect, useState } from 'react';
import sortSvg from 'assets/svg/sort.svg';
import downArrowSvg from 'assets/svg/down-arrow.svg';
import upArrowSvg from 'assets/svg/up-arrow.svg';
import copySvg from 'assets/svg/copy.svg';
import Style from 'components/castingDirector/MatrixItem/MatrixItem.module.css';
import { MatrixAtoms } from 'modules/Show/show.types';
import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import { GridApi } from '@ag-grid-community/core';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import getItemFromLocalStorage from 'utils/getItemFromLocalStorage';
import { debounce } from 'lodash';
import Button from 'components/controls/button/button';
import { ScreenSize } from 'modules/general/general.type';
import { useSelector } from 'react-redux';
import { getRates, getScreenWidth } from 'store/selector/general.selector';
import { SCREEN_SIZE_MOBILE } from 'store/castingPax.constants';
import leftArrowBlueSvg from 'assets/svg/left-arrow-blue.svg';
import editGreenSvg from 'assets/svg/edit-green.svg';
import rightArrowSvg from 'assets/svg/right-arrow.svg';
import upArrowIconSvg from 'assets/svg/uppArrowIcon.svg';
import downArrowFilledBlueSvg from 'assets/svg/downArrowFilledBlue.svg';
import Modal from 'components/controls/Modal/Modal';
import SignInTimeModalBody from 'components/Coordinator/Matrix/Item/SignInTimeModalBody';
import LunchTimeModalBody from 'components/Coordinator/Matrix/Item/LunchTimeModalBody';
import CostumeTimeModalBody from 'components/Coordinator/Matrix/Item/CostumeTimeModalBody';
import WrapTimeModalBody from 'components/Coordinator/Matrix/Item/WrapTimeModalBody';
import PerformeRedirectLink from 'components/castingDirector/MatrixItem/PerformerRedirectLink';
import PerformerTime from 'components/Coordinator/Matrix/Item/PerformerTime';
import MileageTimeModalBody from 'components/Coordinator/Matrix/Item/MileageTimeModalBody';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { UNION_TYPE } from 'helpers/utils';

interface MatrixItemProps {
  atoms: MatrixAtoms;
  grid?: GridApi;
  index: number;
  updateUnionRate: Function;
  handleCopy: Function;
  handlePaste: Function;
  updateGridInstance: Function;
  updateGridDataOnServer: Function;
  updateSignInTime: Function;
  updateLunchTime: Function;
  updateCostumeTime: Function;
  updateWrapTime: Function;
  isMatrixExpanded: boolean;
}

const MatrixItem: FunctionComponent<MatrixItemProps> = (props: MatrixItemProps) => {
  const {
    atoms,
    index,
    grid,
    updateUnionRate,
    handleCopy,
    handlePaste,
    updateGridInstance,
    updateGridDataOnServer,
    updateSignInTime,
    updateLunchTime,
    updateCostumeTime,
    updateWrapTime,
    isMatrixExpanded,
  } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(isMatrixExpanded);
  const [isExpandedWeb, setIsExpandedWeb] = useState<boolean>(true);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [selectedView, setSelectedView] = useState<string>('');
  const rates = useSelector(getRates);

  useEffect(() => {
    if (atoms.isUpdate > 0) {
      if (grid) {
        grid.refreshCells({
          force: true,
        });
      }
    }
  }, [atoms.isUpdate]);

  const onGridReady = (params: any) => {
    const { api } = params;
    api.position = index;
    api.hideOverlay();
    updateGridInstance(index, api);
  };

  const disableCopyOption = () => {
    const copiedPerformer = getItemFromLocalStorage('copyItem');
    return !copiedPerformer;
  };
  const currentScreenSize: ScreenSize = useSelector(getScreenWidth);

  const getContextMenuItems = (params: any) => {
    document.body.click();
    const result: any = [
      {
        name: 'Copy',
        action: () => {
          handleCopy(index, params);
        },
        icon: `<img src=${copySvg} alt="copy icon" />`,
      },
      {
        name: 'Paste',
        action: () => {
          handlePaste(index, params);
        },
        disabled: disableCopyOption(),
        icon: `<img src=${copySvg} alt="copy icon" />`,
      },
      'separator',
    ];

    rates.map((opt: any) =>
      result.push({
        name: `set rate to ${opt.title}`,
        action: () => {
          updateUnionRate(index, params, opt.code);
        },
      }),
    );

    return result;
  };

  const pasteData: string[] = [];
  let gridIndex: number = -1;

  const updateData = debounce(() => processData(), 500);

  const processData = () => {
    updateGridDataOnServer(pasteData, gridIndex);
    pasteData.splice(0, pasteData.length);
    gridIndex = -1;
  };

  const onCellValueChanged = (params: any) => {
    gridIndex = params.api.position;
    if (pasteData.indexOf(params.node.id) === -1) pasteData.push(params.node.id);
    updateData();
  };

  const getRowClass = (params: any) => {
    if (params.data.status === 'requested' || params.data.isDelete) {
      return true;
    }
    return false;
  };

  const rowClassRule = {
    'ag-grid-text-grey': getRowClass,
  };

  const handlePerformerDetail = (e: any) => (
    <PerformeRedirectLink performerId={e.data.performerId} performerName={e.data.performerName} />
  );

  const handleShowModal = (params: any, text: string) => {
    setSelectedItem(params);
    setSelectedView(text);
    setIsShow(true);
  };

  const handleModalTitle = () => {
    if (selectedItem.colDef.field === 'signInTime') {
      return 'Enter Sign In Time';
    }
    if (selectedItem.colDef.field === 'lunch') {
      return 'Enter Lunch Time';
    }
    if (selectedItem.colDef.field === 'costume') {
      return 'Enter Costime Value';
    }
    if (selectedItem.colDef.field === 'wrap') {
      return 'Enter Wrap Time';
    }
    if (selectedItem.colDef.field === 'mileage') {
      return 'Travelling';
    }
    return '';
  };

  const handleShowModalMobile = (text: string) => {
    setSelectedView(text);
    setIsShow(true);
  };

  const handleCellIconClick = (item: any, isCheck: boolean) => {
    const data = item;
    if (data.colDef.field === 'rental') {
      data.data.isRental = isCheck;
      if (!isCheck) {
        data.data.rental = '';
        data.setValue('');
      }
    } else if (data.colDef.field === 'mileage' || data.colDef.field === 'mileageType') {
      handleShowModal(item, 'mileage');
    } else if (data.colDef.field === 'NDB') {
      data.data.NDB = isCheck;
      data.setValue(isCheck);
    } else if (data.colDef.field === 'isAllowancesEnable') {
      data.data.isAllowancesEnable = isCheck;
      gridIndex = item.api.position;
      if (pasteData.indexOf(item.node.id) === -1) pasteData.push(item.node.id);
      updateData();
    }
  };

  const handleModalBody = () => {
    if (selectedView === 'signInTime') {
      return <SignInTimeModalBody handleClose={handleCloseModal} handleSave={handleUpdateSignInTime} />;
    }
    if (selectedView === 'lunch') {
      return <LunchTimeModalBody handleClose={handleCloseModal} handleSave={handleUpdateLunchTime} />;
    }
    if (selectedView === 'costume') {
      return <CostumeTimeModalBody handleClose={handleCloseModal} handleSave={handleUpdateCostumeTime} />;
    }
    if (selectedView === 'wrap') {
      return <WrapTimeModalBody handleClose={handleCloseModal} handleSave={handleUpdateWrapTime} />;
    }
    if (selectedView === 'mileage') {
      return (
        <MileageTimeModalBody item={selectedItem} handleClose={handleCloseModal} handleSave={handleUpdateMileageTime} />
      );
    }
    return '';
  };

  const handleCloseModal = () => {
    setSelectedView('');
    setSelectedItem(null);
    setIsShow(false);
  };

  const handleUpdateSignInTime = (text: string) => {
    updateSignInTime(index, text);
    setIsShow(false);
    setSelectedItem(null);
  };

  const handleUpdateLunchTime = (text: string) => {
    if (selectedView) {
      updateLunchTime(index, text);
      setSelectedItem(null);
      setSelectedView('');
    }
  };

  const handleUpdateCostumeTime = (text: string) => {
    if (selectedView === 'costume') {
      updateCostumeTime(index, text);
    }
    return '';
  };

  const handleUpdateWrapTime = (text: string) => {
    if (selectedView === 'wrap') {
      updateWrapTime(index, text);
    }
    return '';
  };

  const handleUpdateMileageTime = (mileageData: { mileage: number; mileageType: number }) => {
    const data = selectedItem;
    data.data.mileageType = mileageData.mileage;
    data.data.mileage = mileageData.mileageType;
    gridIndex = selectedItem.api.position;
    if (pasteData.indexOf(selectedItem.node.id) === -1) pasteData.push(selectedItem.node.id);
    updateData();
  };

  const handleUpdateRental = (params: any) => {
    const data = params;
    if (data.newValue && data.data.isRental) {
      if (params.newValue.includes('$')) data.data.rental = data.newValue;
      else data.data.rental = data.newValue;
      return true;
    }
    if (!data.data.isRental) {
      data.data.rental = '';
      return true;
    }
    return false;
  };

  const handleSignInTime = (params: any) => {
    const data = params;
    if (params.newValue === '') {
      data.data.signInTime = '';
      return true;
    }
    let validTime;
    if (params.newValue) validTime = params.newValue.replaceAll(/\s/g, '');
    const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])?$/.test(validTime);

    if (isValid) {
      data.data.signInTime = params.newValue.replaceAll(/\s/g, '');
      return true;
    }
    data.data.signInTime = params.oldValue;
    return false;
  };

  const isValidLunchTime = (timeVal: string) => {
    const arr: string[] = timeVal.split(':');
    console.log(arr);
    if (arr[1] === '00') return true;
    return parseInt(arr[1], 10) % 6 === 0;
  };

  const handleLunchTime = (params: any) => {
    const lunchData = params;
    if (lunchData.newValue) {
      const timeValue = lunchData.newValue.replaceAll(/\s/g, '').toLowerCase();
      const isValidTimeFormat = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])-([0-1]?[0-9]|2[0-4]):([0-5][0-9])?$/.test(
        timeValue,
      );

      if (isValidTimeFormat) {
        const arr: any = timeValue.split('-');
        if (!isValidLunchTime(arr[0])) {
          return false;
        }
        const startTime: number = parseInt(arr[0].replace(':', ''), 10);
        const endTime: number = parseInt(arr[1].replace(':', ''), 10);
        const diff: number = calculateTotalTimeWorked(arr[0], arr[1]);
        if ((diff === 30 || diff === 60) && endTime > startTime) {
          lunchData.data.lunch = timeValue;
          return true;
        }
      }
    } else {
      lunchData.data.lunch = '';
      return true;
    }
    return false;
  };

  const calculateTotalTimeWorked = (startTime: string, endTime: string) => {
    const startValues = startTime.split(':');
    const endValues = endTime.split(':');
    let ttlHours = 0;
    if (startValues.length > 1 && endValues.length > 1) {
      ttlHours = parseInt(endValues[0], 10) - parseInt(startValues[0], 10);
      if (ttlHours > 0) {
        ttlHours *= 60;
      }
      ttlHours -= parseInt(startValues[1], 10);
      ttlHours += parseInt(endValues[1], 10);
    }
    return ttlHours;
  };

  const handleCostumeTime = (params: any) => {
    const isValid = /^([0-9])$/.test(params.newValue);
    const data = params;
    if (params.newValue === '') {
      data.data.costume = '';
      return true;
    }
    if (isValid) {
      data.data.costume = params.newValue;
      return true;
    }
    data.data.costume = params.oldValue;
    return false;
  };

  const handleWrapTime = (params: any) => {
    const data = params;
    if (params.newValue === '') {
      data.data.wrapTime = '';
      return true;
    }
    let validTime: any;
    if (params.newValue) validTime = params.newValue.replaceAll(/\s/g, '');
    const isValid = /^([0-3]?[0-9]|4[0-8]):([0-5][0-9])?$/.test(validTime);
    if (isValid) {
      data.data.wrapTime = params.newValue.replaceAll(/\s/g, '');
      return true;
    }
    data.data.wrapTime = params.oldValue;
    return false;
  };

  const customRenderCell = (e: any) => <PerformerTime params={e} handleCellIconClick={handleCellIconClick} />;

  const rentalEditable = (params: any) => params.data.isRental && isPerformerEditable(params);

  const isPerformerEditable = (params: any) =>
    params.data.status === 'booked' && !params.data.invoiceId && !params.data.isDelete;

  const handleNdb = (e: any) => <PerformerTime params={e} handleCellIconClick={handleCellIconClick} />;

  const handleRate = (params: any) => {
    const data = params;
    if (params.newValue === '') {
      data.data.rate = '';
      return true;
    }
    const code = rates.map((c: any) => c.code);
    if (code.includes(params.newValue)) {
      data.data.rate = params.newValue;
      return true;
    }
    if (/^-?\d+\.?\d*$/.test(params.newValue)) {
      data.data.rate = params.newValue;
      return true;
    }
    data.data.rate = params.oldValue;
    return false;
  };

  const columnsWithDefaults = [
    {
      field: 'performerName',
      minWidth: 150,
      sortable: true,
      checkboxSelection: true,
      cellRenderer: 'handlePerformerDetail',
      headerName: 'Name',
      headerTooltip: 'Name',
    },
    {
      field: 'callTime',
      minWidth: 80,
      headerName: 'callTime',
      headerTooltip: 'callTime',
    },
    {
      field: 'signInTime',
      minWidth: 80,
      editable: isPerformerEditable,
      valueSetter: handleSignInTime,
      headerName: 'SignInTime',
      headerTooltip: 'SignInTime',
    },
    {
      field: 'unionNo',
      minWidth: 90,
      headerName: 'UnionNo',
      headerTooltip: 'UnionNo',
    },
    {
      field: 'isAllowancesEnable',
      minWidth: 80,
      headerName: 'Voucher',
      cellRenderer: 'customRenderCell',
      headerTooltip: 'Voucher',
    },
    {
      field: 'rate',
      minWidth: 80,
      headerName: 'Rate',
      valueSetter: handleRate,
      editable: isPerformerEditable,
      headerTooltip: 'Rate',
    },
    {
      field: 'role',
      minWidth: 80,
      headerName: 'Role',
      headerTooltip: 'Role',
    },
    {
      field: 'voucherType',
      sortable: true,
      editable: true,
      minWidth: 150,
      headerName: 'Select Voucher Type',
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: UNION_TYPE,
      },
    },
    {
      field: 'NDB',
      minWidth: 60,
      cellRenderer: 'handleNdb',
      headerName: 'NDB',
      headerTooltip: 'NDB',
    },
    {
      field: 'lunch',
      minWidth: 100,
      editable: isPerformerEditable,
      valueSetter: handleLunchTime,
      headerName: 'Lunch',
      headerTooltip: 'Lunch',
    },
    {
      field: 'costume',
      minWidth: 80,
      editable: isPerformerEditable,
      valueSetter: handleCostumeTime,
      headerName: 'Costume',
      headerTooltip: 'Costume',
    },
    {
      field: 'wrapTime',
      minWidth: 80,
      editable: isPerformerEditable,
      valueSetter: handleWrapTime,
      headerName: 'wrapTime',
      headerTooltip: 'wrapTime',
    },
    {
      field: 'rental',
      minWidth: 80,
      editable: rentalEditable,
      valueSetter: handleUpdateRental,
      cellRenderer: 'customRenderCell',
      headerName: 'Rental',
      headerTooltip: 'Rental',
    },
    {
      field: 'mileage',
      minWidth: 100,
      cellRenderer: 'customRenderCell',
      headerName: 'Mileage',
      headerTooltip: 'Mileage',
    },
    {
      field: 'note',
      minWidth: 100,
      headerName: 'Note',
      headerTooltip: 'Note',
    },
  ];

  const [columns] = useState<any>(columnsWithDefaults);

  const showAgGrid = () => (
    <AgGridReact
      modules={[MenuModule, ClientSideRowModelModule, RangeSelectionModule, ExcelExportModule, RichSelectModule]}
      frameworkComponents={{
        handlePerformerDetail,
        handleLunchTime,
        customRenderCell,
        handleNdb,
        handleRate,
      }}
      getRowNodeId={(data) => data.id}
      domLayout="autoHeight"
      rowHeight={30}
      headerHeight={40}
      rowData={atoms.performers}
      enableMultiRowDragging
      rowDragManaged
      animateRows
      enableRangeSelection
      suppressMultiRangeSelection
      enableFillHandle
      suppressContextMenu={false}
      suppressHorizontalScroll={false}
      suppressRowClickSelection
      undoRedoCellEditing
      undoRedoCellEditingLimit={1}
      defaultColDef={{
        flex: 1,
        minWidth: 15,
        resizable: true,
        headerComponentParams: { menuIcon: 'fa-bars' },
      }}
      onGridReady={(params) => onGridReady(params)}
      getContextMenuItems={getContextMenuItems}
      popupParent={document.body}
      rowClassRules={rowClassRule}
      onCellValueChanged={onCellValueChanged}
    >
      {columns.map((column: any) => (
        <AgGridColumn {...column} key={column.field} />
      ))}
    </AgGridReact>
  );

  if (currentScreenSize === SCREEN_SIZE_MOBILE) {
    return (
      <>
        <div>
          <div className="d-flex align-items-center justify-content-between py-2">
            <div
              onClick={() => setIsExpanded(!isExpanded)}
              onKeyUp={() => setIsExpanded(!isExpanded)}
              role="button"
              tabIndex={0}
              className="d-flex align-items-center"
            >
              <div className="d-flex flex-column">
                <img src={upArrowIconSvg} alt="upp arrow" className={Style.sortableArrowIcons} />
                <img src={downArrowFilledBlueSvg} alt="down arrow" className={Style.sortableArrowIcons} />
              </div>
              <h4 className={`mb-0 ml-2 ${Style.extrasTitle}`}>{atoms.title}</h4>
            </div>
            <div
              onClick={() => setIsExpanded(!isExpanded)}
              onKeyUp={() => setIsExpanded(!isExpanded)}
              role="button"
              tabIndex={0}
            >
              <img src={rightArrowSvg} alt="right arrow" />
            </div>
          </div>
        </div>
        {isExpanded && (
          <div className={Style.extraMatrixItemWrapper}>
            <div className={Style.ItemWrapperHeader}>
              <div className="d-flex align-items-center">
                <div
                  onClick={() => setIsExpanded(!isExpanded)}
                  onKeyUp={() => setIsExpanded(!isExpanded)}
                  role="button"
                  tabIndex={0}
                >
                  <img src={leftArrowBlueSvg} alt="back arrow" />
                </div>
                <h4 className={`mb-0 ml-2 ${Style.HeaderTitle}`}>{atoms.title}</h4>
              </div>
              <img src={editGreenSvg} alt="editGreenSvg" />
            </div>
            <div key={index} className="inner-col ag-theme-alpine">
              {showAgGrid()}
            </div>
            <div className={Style.itemMatrixFooter}>
              <Button
                type="secondary"
                cssClass={Style.lunchButton}
                clickHandler={() => handleShowModalMobile('signInTime')}
                label="signIn"
              />
              <Button
                type="primary"
                cssClass={Style.lunchButton}
                clickHandler={() => handleShowModalMobile('lunch')}
                label="Lunch"
              />
              <Button
                type="secondary"
                cssClass={Style.lunchButton}
                clickHandler={() => handleShowModalMobile('costume')}
                label="Costume"
              />
              <Button
                type="primary"
                cssClass={Style.lunchButton}
                clickHandler={() => handleShowModalMobile('wrap')}
                label="Wrap"
              />
            </div>
          </div>
        )}
        {isShow && <Modal title="Enter Time" body={handleModalBody} closeModal={() => handleCloseModal()} />}
      </>
    );
  }

  return (
    <div className={`${Style.matrixItemWrapper}`}>
      <div className={`d-flex justify-content-between align-items-center ${Style.matrixItemInner}`}>
        <div className={`d-flex justify-content-between align-items-center ${Style.setLayoutWrapper}`}>
          <div className={`d-flex justify-content-between align-items-center ${Style.sortingWrapper}`}>
            <img src={sortSvg} alt="sort icon" className={`mr-3 ${Style.sortImage}`} />
            <p className="mb-0">{atoms.title}</p>
          </div>
        </div>
        <div
          onClick={() => setIsExpandedWeb(!isExpandedWeb)}
          onKeyUp={() => setIsExpandedWeb(!isExpandedWeb)}
          role="button"
          tabIndex={0}
        >
          <img src={isExpandedWeb ? upArrowSvg : downArrowSvg} alt="" className={Style.matrixItemDownArrow} />
        </div>
      </div>

      {isExpandedWeb && (
        <div>
          <div className="inner-col ag-theme-alpine">{showAgGrid()}</div>
        </div>
      )}
      {isShow && <Modal title={handleModalTitle()} body={handleModalBody} closeModal={() => handleCloseModal()} />}
    </div>
  );
};

export default MatrixItem;
