import React, { useState, useMemo, useEffect, useRef } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { connect } from 'react-redux';
import { updateVoucherStatusBegin, createVoucherBegin } from 'store/actions/payroll.actions';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit, faRemoveFormat } from '@fortawesome/free-solid-svg-icons';
import { getTimeDifference } from 'helpers/utils';
import downloadLogo from 'assets/svg/downloadBtn.svg';
import InvoicePDF from 'components/Payroll/VoucherManagement/Invoice/index';
import BreakDown from 'components/Payroll/VoucherManagement/BreakDown/index';
import PopoverContainer from 'components/controls/Popover/PopoverContainer';
import TravelCellRenderer from './components/TravelCellRenderer';
// import VoucherComponent from './components/VoucherComponent';
import DocsComponent from './components/DocsComponent';
import PopupCellRenderer from './components/StatusCellRenderer';
// import CheckControls from './components/CheckControls';
// import ActionButtons from './components/ActionButtons';
import PerformerCellRenderer from './components/PerfomerCellRenderer';
import './styles/agGrid.css';
import Loader from '../../../../controls/Loader/Loader';

// import Style from './ShowInfoGrid.module.css';

interface Props {
  performers: any;
  refValue: any;
  updateVoucherStatus: Function;
  createVoucher: Function;
  render: Function;
  handleChange: Function;
}

interface GridData {
  name: any;
  rate: string;
  performerNote: string;
  travel: string;
  mealPenalty: string;
  rental: string;
  changes: string;
  voucher: string;
  docs: string;
  totalHours: number;
  total: number;
  checked: boolean;
  status?: boolean;
}

const ShowInfoGrid: React.FC<Props> = (props: Props) => {
  const { updateVoucherStatus, createVoucher, performers, refValue, render, handleChange } = props;
  const [, setGridApi] = useState();
  const [invoiceStyle, setInvoiceStyle] = useState({ visibility: 'hidden' });
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const arr: any = [];
  const pdfRef: any = useRef(null);
  const cellStyle = useMemo(
    () => ({
      color: '#828282',
      paddingLeft: '5px',
    }),
    [],
  );
  const [data, setData] = useState<GridData[]>(performers);
  useEffect(() => {
    setData(performers);
  }, [data]);
  const onButtonClick = (d: any) => {
    const row = d;
    if (d.signInTime !== null) {
      if (d?.wrapTime) {
        row.totalTime = getTimeDifference(d.signInTime, d?.wrapTime);
      }
      const basicInfo = d.voucherBasicData;
      if (basicInfo) {
        setSelectedRow({ ...d, ...basicInfo });
      } else {
        setSelectedRow(d);
      }
      setIsLoading(true);
    }
  };

  console.log('performers3333', performers);

  const paramsRenderer = (pro: any) => (
    <DivContainer>
      <p>Voucher</p>
      <IconWrapper>
        <Image src={downloadLogo} alt="" onClick={() => onButtonClick(pro.data)} />
      </IconWrapper>
    </DivContainer>
  );
  const rentalRenderer = (pro: any) => (
    <div className="text-center">
      <input type="checkbox" checked={pro.data.isRental} />
    </div>
  );
  // const handleChange = (e: any, item: any) => {
  //   const isChecked = e.target.checked;
  //   if (isChecked) {
  //     // console.log('ischeked', isChecked, performersArr, item, [...performersArr, item]);
  //     arr.push(item);
  //     console.log('Array...', arr);
  //     // setPerformersArr([...performersArr, item]);
  //   } else {
  //     // console.log('elseChecked', isChecked, performersArr, item, [...performersArr, item]);
  //     // const index = performersArr.findIndex((obj: any) => obj._id === item._id);
  //     const index = arr.findIndex((obj: any) => obj._id === item._id);
  //     if (index > -1) {
  //       // const a = [...arr];
  //       arr.splice(index, 1);
  //       console.log('Array...', arr);
  //       // a.splice(index, 1);
  //       // setPerformersArr([...a]);
  //     }
  //   }
  // };
  const performerNameCell = (pro: any) => (
    <PopoverContainer cssClass="p-2" itemComponent={() => {}} toolTip={pro.data.performerName}>
      <div className="d-flex">
        <input type="checkbox" onChange={(e: any) => handleChange(e, pro.data)} />
        <p className="mb-0">{pro.data.performerName}</p>
      </div>
    </PopoverContainer>
  );

  const actionButtons = (params: any) => {
    if (params.data.wrapTime !== null && params.data.signInTime !== null) {
      const handleClick = (type: string) => {
        updateVoucherStatus({
          matrixId: params.data._id,
          status: type,
        });
        createVoucher({
          workingDayId: params.data.workingDayId._id,
          performerId: params.data.performerId,
          data: params.data,
        });
        render(true);
      };
      return (
        <Container>
          {/* <ActionIconWrapper color="red" onClick={() => handleClick('rejected')}> */}
          {/*  <FontAwesomeIcon icon={faTimes} /> */}
          {/* </ActionIconWrapper> */}
          <select
            name=""
            id=""
            className="form-control action-dropdown mb-0"
            style={{ fontFamily: "'Lato', 'Font Awesome 5 Free'" }}
          >
            <option value="Edit">&#xf044;</option>
            <option value="Delete">&#xf2ed;</option>
          </select>

          <ActionIconWrapper color="#49D360" onClick={() => handleClick('approved')}>
            <FontAwesomeIcon icon={faCheck} />
          </ActionIconWrapper>
        </Container>
      );
    }
    return <></>;
  };

  const rateValueGetter = (params: any) => (params.data.jobId?.rate !== undefined ? params.data.jobId?.rate.title : '');

  const totalTimeValueGetter = (params: any) => {
    if (params.data.wrapTime !== null && params.data.signInTime !== null) {
      const diff = getTimeDifference(params.data.signInTime, params.data.wrapTime);
      if (params.data.mileage !== null) {
        const timeDiff: any = diff.split(':');
        const addMinutes = parseInt(timeDiff[1], 10) + parseInt(params.data.mileage, 10);
        if (addMinutes > 60) {
          const hours = Math.floor(addMinutes / 60);
          const remainingMinutes = addMinutes % 60;
          return `${hours}:${remainingMinutes >= 10 ? remainingMinutes : `0${remainingMinutes}`}`;
        }
        return `${timeDiff[0]}:${addMinutes >= 10 ? addMinutes : `0${addMinutes}`}`;
      }
      return getTimeDifference(params.data.signInTime, params.data.wrapTime);
    }
    return '';
  };

  const totalAmountValueGetter = (params: any) => {
    if (params.data.wrapTime !== null && params.data.signInTime !== null) {
      const item = params.data;
      const totalAmount = item.totalWageAmount + item.totalCostumeAmount + item.totalMileageAmount || 0;
      return totalAmount;
    }
    return 0;
  };

  const gridData = {
    columnDefs: [
      {
        headerName: '',
        field: 'status',
        width: 35,
        cellRendererFramework: PopupCellRenderer,
      },
      {
        headerName: 'Name',
        field: 'performerName',
        // width: 250,
        rowDrag: true,
        rowDragEntireRow: true,
        cellRendererFramework: performerNameCell,
        cellStyle: { color: '#2F80ED', paddingLeft: '5px' },
        headerTooltip: 'Name',
      },
      { headerName: 'Rate', field: '', valueGetter: rateValueGetter, width: 120, cellStyle, headerTooltip: 'Rate' },
      {
        headerName: 'Performer Note',
        field: 'performerRequestMessage',
        // width: 145,
        cellRendererFramework: PerformerCellRenderer,
        cellStyle,
        headerTooltip: 'Performer Note',
      },
      {
        headerName: 'Travel',
        field: 'travel',
        // width: 100,
        cellRendererFramework: TravelCellRenderer,
        cellStyle,
        headerTooltip: 'Travel',
      },
      {
        headerName: 'Rental',
        // width: 70,
        field: 'rental',
        cellRendererFramework: rentalRenderer,
        cellStyle,
        headerTooltip: 'Rental',
      },
      {
        headerName: 'Meal Penalty',
        field: 'mealPenalty',
        // width: 110,
        cellStyle,
        headerTooltip: 'Meal Penalty',
      },
      { headerName: 'Changes', field: 'costume', width: 80, cellStyle, headerTooltip: 'Name' },
      {
        headerName: 'Voucher',
        // width: 100,
        field: 'voucher',
        headerTooltip: 'Voucher',
        cellRendererFramework: paramsRenderer,
      },
      { headerName: 'Docs', width: 80, field: 'docs', cellRendererFramework: DocsComponent, headerTooltip: 'docs' },
      {
        headerName: 'Total Hours',
        field: '',
        valueGetter: totalTimeValueGetter,
        width: 100,
        cellStyle,
        headerTooltip: 'Total Hours',
      },
      {
        headerName: 'Total $',
        width: 85,
        field: 'total',
        valueGetter: totalAmountValueGetter,
        cellStyle,
        headerTooltip: 'Total $',
      },
      {
        headerName: 'Action',
        field: 'total',
        width: 150,
        cellRendererFramework: actionButtons,
        headerTooltip: 'Action',
      },
    ],
    rowData: performers,
  };
  const rowStyle = { background: 'white' };
  const getRowStyle = (params: any) => {
    if (params.data.voucherStatus === 'approved') {
      return { background: 'rgba(73,211,96,0.1)', color: 'white' };
    }
    if (params.data.voucherStatus === 'rejected') {
      return { background: '#F2F2F2' };
    }
    return null;
  };

  // const onCellClicked = (params: any) => {
  //   updateVoucherStatus({
  //     matrixId: params.data._id,
  //     status: localStorage.getItem('btnType'),
  //   });
  //   localStorage.removeItem('btnType');
  //   render(true);
  // };
  const onGridReady = (params: any) => {
    setGridApi(params);
    params.api.sizeColumnsToFit();
  };

  return (
    <>
      <div
        className="ag-theme-alpine"
        ref={refValue}
        style={{
          // height: '172px',
          height: '675px',
          width: '100%',
          fontSize: '14px',
          margin: '0px',
          fontFamily: 'Montserrat',
          fontStyle: 'normal',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          borderRadius: '50px !important',
        }}
      >
        <AgGridReact
          columnDefs={gridData.columnDefs}
          rowStyle={rowStyle}
          getRowStyle={getRowStyle}
          // onCellClicked={onCellClicked}
          headerHeight={43}
          rowData={gridData.rowData}
          modules={AllCommunityModules}
          defaultColDef={{ filter: true, resizable: false }}
          onGridReady={onGridReady}
          enableBrowserTooltips
          rowDragManaged
          rowSelection="multiple"
        />
      </div>
      {/* <div style={{ display: 'none' }}> */}
      {isLoading && (
        <>
          <ShowLoader>{isLoading && <Loader isSmallView />}</ShowLoader>
          <div className="w-100">
            <InvoicePDF item={selectedRow} isDownload isLoading={setIsLoading} />
          </div>
        </>
      )}
      {/* </div> */}
    </>
  );
};

const mapDispatchToProps = {
  updateVoucherStatus: updateVoucherStatusBegin,
  createVoucher: createVoucherBegin,
};

export default connect(null, mapDispatchToProps)(ShowInfoGrid);

const DivContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & p {
    margin-bottom: 0px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #828282;
  }
`;

const Image = styled.img`
  height: 16px;
  width: 16px;

  &:hover {
    cursor: pointer;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const ActionIconWrapper = styled.div<{ color: string }>`
  height: 100%;
  width: 12px;
  color: ${({ color }) => color};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
`;

const Container = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
`;
const ShowLoader = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;
