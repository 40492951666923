import { GridApi } from '@ag-grid-community/core';
import { getCurrentDate } from 'helpers/utils';
import { Options, RateOptions } from 'modules/general/general.type';
import { Pagination } from 'modules/jobs/types';
import { matrixNote } from 'modules/params/param.type';
import { User, UserList } from 'modules/user/types';
import moment from 'moment';

export type Show = {
  id: string;
  title: string;
  showType: ShowTypes;
  remarks?: string;
  additionalInfo?: string;
  episodes?: EpisodeList;
  showWorkingDays?: EpisodeDayList;
  users?: UserList;
  invitationList?: InvitationList;
  isNew?: boolean;
  isReadOnly?: boolean;
  showJobs?: ShowDayJobList;
  updated?: string;
  templateId?: string;
  team: TeamObject;
  matrix: MatrixObject;
  createdBy: string;
  attachements: string[];
  imageFile?: string;
  showImage?: string;
  createdAt?: string;
  date?: string;
};

export type Rate = {
  id?: string;
  rate: number;
  code: string;
  title: string;
  createdAt?: string;
  updatedAt?: string;
  isActive?: boolean;
};

export type ShowTeamMember = {
  id: string;
  showId: Show;
  userId: User;
  userRole: string;
  showRole: string;
  createdBy: string;
  callBack?: Function;
};

export type TeamMember = {
  id: string;
  showId: string;
  userId: string;
  userRole: string;
  showRole: string;
  createdBy: string;
  callBack?: Function;
};

export type TeamObject = {
  data: ShowTeamMember[];
  pagination: Pagination;
};

export type MatrixObject = {
  data: MatrixType[];
  pagination: Pagination;
  stats: ShowMatrixStats[];
};

export type IUserRoleParam = {
  id: string;
  role: string;
};

export interface ShowCreateParams {
  showId: string;
  title: string;
  showType: ShowTypes;
  remarks?: string;
  additionalInfo?: string;
  userIds?: IUserRoleParam[];
  episodes: EpisodeList;
  workingDays: EpisodeDayList;
  invitationList?: InvitationList;
  deleteDates?: string[];
  templateId?: string;
  attachements: string[];
  imageFile?: string;
  removeUser?: string[];
  removeInvitation?: string[];
  productionName?: string;
  productionPhone?: string;
}

export type Episode = {
  id: string;
  episodeNo: number;
  title?: string;
  date?: string;
  episodeDays: EpisodeDayList;
  isNew?: boolean;
};

export type ShowWorkingDay = {
  id: string;
  episodeId?: string;
  showId?: string;
  title?: string;
  dayTitle?: string;
  date: string;
  isNew?: boolean;
  type: string;
  isDelete?: boolean;
  jobs?: ShowDayJobList;
  atoms?: MatrixAtomsList;
  budget?: string;
  isDateChange?: boolean;
  bgCoordinator?: string[];
};

export type MatrixType = {
  id: string;
  episodeId?: Episode;
  showId: Show;
  title: string;
  dayTitle?: string;
  date: string;
  isNew?: boolean;
  type: string;
  isDelete?: boolean;
  jobs?: ShowDayJobList;
  atoms?: MatrixAtomsList;
  bgCoordinator: UserList;
  bgInvitation?: string[];
  performers: PerformerItem[];
  prelimEmailTemplateId?: string;
  finalEmailTemplateId?: string;
  callSheetUrl?: string;
  callSheet?: string;
  status?: string;
  crewCall?: string;
};

export type MatrixItemList = MatrixType[];
export type IShowData = {
  showBudget: {
    budget: string;
    expense: string;
  };
  showTitle: string;
  season: string;
  episode: string;
};

export type PerformerItem = {
  id: string;
  date: string;
  workingDayId: string;
  showId: string;
  jobId: string;
  bookingId: string;
  atoms: string;
  performerId: string;
  performerName: string;
  role: string;
  callTime?: string;
  signInTime?: string;
  wrapTime?: string;
  unionNo?: string;
  unionStatus?: string;
  sequence: number;
  isDelete: boolean;
  deleteReason?: string;
  note?: string;
  message?: string;
  rate?: string;
  status: string;
  deleteSeenBy?: string[];
  prevJobId?: string;
  prevBookingId?: string;
  prevMatrixId?: string;
  columns?: string[];
  updateActionType?: string;
  lunch: string[];
  costume: string;
  rental: string;
  isRental: boolean;
  mileage: string;
  mileageType: string;
  NDB: boolean;
  isDataUpdate: boolean;
  isAllowancesEnable: boolean;
  voucherType?: string;
};

export type PerformerWorkedDay = {
  signInTime: any;
  voucherBasicData: any;
  id: string;
  date: string;
  workingDayId: string;
  showId: Show;
  jobId: string;
  bookingId: string;
  atoms: string;
  performerId: string;
  performerName: string;
  role: string;
  callTime?: string;
  unionNo?: string;
  sequence: number;
  isDelete: boolean;
  deleteReason?: string;
  note?: matrixNote[];
  message?: string;
  rate?: string;
  status: string;
  deleteSeenBy?: string[];
  prevJobId?: string;
  prevBookingId?: string;
  prevMatrixId?: string;
  totalWageAmount: number;
  totalCostumeAmount: number;
  totalMileageAmount: number;
  voucherStatus?: string;
  voucher?: any;
  performerVoucherStatus?: string;
  performerRequestMessage: string;
};

export type MatrixAtoms = {
  id: string;
  workingDayId: string;
  showId: string;
  title: string;
  sequence: number;
  performers: PerformerItem[];
  isUpdate: number;
  grid?: GridApi;
};

export type MatrixAtomsList = MatrixAtoms[];

export type ShowDayJob = {
  id: string;
  showId?: string;
  workingDayId: string[];
  title: string;
  description?: string;
  startDate: string;
  date: string;
  dateInNumber?: number;
  category?: string;
  location: Options;
  locationId: string;
  maxHeight?: string;
  minHeight?: string;
  weight?: string;
  maxWeight?: string;
  waist?: string;
  maxWaist?: string;
  neck?: string;
  maxNeck?: string;
  jacket?: string;
  maxJacket?: string;
  inseam?: string;
  maxInseam?: string;
  sleeve?: string;
  maxSleeve?: string;
  hair?: string;
  eyes?: string;
  age?: string;
  maxAge?: string;
  chest?: string;
  maxChest?: string;
  shoes?: string;
  maxShoes?: string;
  ethnicity?: string;
  clothingOption?: string;
  bodyRequirements?: string;
  costumeNote?: string;
  remarks?: string;
  additionalInfo?: string;
  createdBy?: string;
  status?: string;
  isActive: boolean;
  noPerformerRequired: number;
  dates: string[];
  isSaveForLater?: boolean;
  templateId?: string;
  messageId?: string;
  isRequested?: boolean;
  bookedJobs?: number;
  pendingJobs?: number;
  rate: RateOptions;
  rateId: string;
  requestCancelSlug?: string;
  requestCancelBy?: string;
};

export type CloseOrDeleteShowJob = {
  id: string;
  showId: string;
  status: string;
};

export type ShowJob = {
  id: string;
  showId: Show;
  workingDayId: string[];
  title: string;
  description?: string;
  startDate: string;
  date: string;
  dateInNumber?: number;
  category?: string;
  location: Options;
  locationId: string;
  maxHeight?: string;
  minHeight?: string;
  weight?: string;
  maxWeight?: string;
  waist?: string;
  maxWaist?: string;
  neck?: string;
  maxNeck?: string;
  jacket?: string;
  maxJacket?: string;
  inseam?: string;
  maxInseam?: string;
  sleeve?: string;
  maxSleeve?: string;
  hair?: string;
  eyes?: string;
  age?: string;
  maxAge?: string;
  chest?: string;
  maxChest?: string;
  shoes?: string;
  maxShoes?: string;
  ethnicity?: string;
  clothingOption?: string;
  bodyRequirements?: string;
  costumeNote?: string;
  remarks?: string;
  additionalInfo?: string;
  createdBy?: string;
  status?: string;
  isActive: boolean;
  noPerformerRequired: number;
  dates: string[];
  isSaveForLater: boolean;
  templateId?: string;
  isRequested?: boolean;
  bookedJobs?: JobBookingIds[];
  pendingJobs?: JobBookingIds[];
};

export type Invitation = {
  id: string;
  email: string;
  status: string;
  userRole: string;
  isNew?: boolean;
};

export type JobBookingIds = {
  performerId: string;
};

export type InvitationList = Invitation[];

export type ShowDayJobList = ShowDayJob[];

export type EpisodeList = Episode[];

export type EpisodeDayList = ShowWorkingDay[];

export type ShowList = Show[];

export type RateList = Rate[];
export type RateLists = { data: Rate[]; customRate: Rate[] };

export type EmailTemplate = {
  id: string;
  title: string;
  templateHtml: string;
  showId?: string;
  templateType: string;
  messageType?: string;
};

export type EmailTemplateList = EmailTemplate[];

export enum ShowTypes {
  FILM = 'Film',
  SERIES = 'Series',
  NONE = '',
}

export const SORT_FULL_UNION = 'fullUnion';
export const SORT_NO_UNION = 'noUnion';

export interface IRoleValidationError {
  title?: string;
  performer?: string;
  date?: string;
  location?: string;
  emailTemplate?: string;
  requestText?: string;
}
export const roleValidationErrorObj: IRoleValidationError = {
  title: '',
  performer: '',
  date: '',
  location: '',
  emailTemplate: '',
  requestText: '',
};

export type ShowTopCalendarType = {
  id: string;
  date: string;
  title: string;
  dayTitle: string;
  workingDayId: string;
  isEmpty?: boolean;
};

export interface DateJobRequestParams {
  date?: string;
  showId?: string;
  workingDayId?: string;
}

export type ShowStats = {
  users: number;
};

export type ShowMatrixStats = {
  id: string;
  count: number;
};

export type ShowTopCalendarTypeList = ShowTopCalendarType[];

export const newEpisode = (ttlEpisodes: number) => {
  const id: string = Math.random().toString();
  return {
    id,
    episodeNo: ttlEpisodes,
    title: `Episode  ${ttlEpisodes}`,
    isNew: true,
    episodeDays: [
      {
        id: Math.random().toString(),
        episodeId: id,
        title: 'Day 1',
        dayTitle: 'E1 D1',
        date: getCurrentDate(),
        isNew: true,
        type: 'episode',
      },
    ],
  };
};

export const newShowDate = (showId: string, ttlDates: number, date: string) => {
  const id: string = Math.random().toString();
  console.log('new Date', date);
  return {
    id,
    showId,
    title: `Day ${ttlDates + 1}`,
    date: date ? `${moment(date).add(1, 'days').format('YYYY-MM-DD')} 00:00:00` : getCurrentDate(),
    isNew: true,
    type: 'film',
    isDelete: false,
  };
};

export type UpdatePerformerMatrix = {
  performerId: string;
  workingDayIds: string[];
  jobId: string;
  status: string;
  deleteDayIds: string[];
};

export type MatrixChartStats = {
  id: string;
  date: string;
  count: number;
  actualData: number;
  color: string;
  title: string;
};

export type MatrixChartData = {
  id: string;
  budget: string;
  episodeId: number;
  showId: number;
  title: string;
};

export type UserCount = {
  id: string;
  users: number;
};

export type MatrixRedirect = {
  id: string;
  showId?: string;
  date: string;
};

export type PeformerJobDates = {
  userId: string;
  dates: string[];
};

export type callSheet = {
  file: string;
  type: string;
};

export type callSheetList = callSheet[];
